import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-paymentsucess',
  templateUrl: './paymentsucess.component.html',
  styleUrls: ['./paymentsucess.component.css']
})
export class PaymentsucessComponent implements OnInit {

  response: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
  ) { 
    this.response = this.route.snapshot.queryParams;
   }

  ngOnInit() {
    this.dataService.paymentResponse(this.response).subscribe(
      data=>{
        console.log(data);
      }
    );
  }

}
