import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CarouselModule as Alias } from 'ngx-owl-carousel-o';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { AuthInterceptor } from './auth.interceptor';
import { IconsModule } from './module/icons.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ServicesComponent } from './pages/services/services.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubcategoriesComponent } from './pages/subcategories/subcategories.component';
import { OffshellproductlistingComponent } from './pages/offshellproductlisting/offshellproductlisting.component';
import { DimensionComponent } from './pages/dimension/dimension.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxGalleryModule } from 'ngx-gallery';
import { CataloguelistingComponent } from './pages/cataloguelisting/cataloguelisting.component';
import { CatalogudesigningComponent } from './pages/catalogudesigning/catalogudesigning.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { LightboxModule } from 'ngx-lightbox';
import { ProductdetailsComponent} from './pages/productdetails/productdetails.component';
import { CartComponent } from './pages/cart/cart.component';
import { CompareproductsComponent } from './pages/compareproducts/compareproducts.component';
import { EmbedVideo } from 'ngx-embed-video';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SidebarModule } from 'ng-sidebar';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatSidenavModule} from '@angular/material';
import {DemoMaterialModule} from './pages/catalogudesigning/material-module';
import { ThemeforentirehouseComponent } from './pages/themeforentirehouse/themeforentirehouse.component';
import { LoginpageComponent } from './pages/loginpage/loginpage.component';
import { OtppageComponent } from './pages/otppage/otppage.component';
import { FinishpageComponent } from './pages/finishpage/finishpage.component';
import { BHKComponent } from './pages/bhk/bhk.component';
import { RoomsforentirehouseComponent } from './pages/roomsforentirehouse/roomsforentirehouse.component';
import { MarketingvideoComponent } from './pages/marketingvideo/marketingvideo.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BranchloginComponent } from './pages/branchlogin/branchlogin.component';
import { ElementlistComponent } from './pages/elementlist/elementlist.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { WelcomepageComponent } from './pages/welcomepage/welcomepage.component';
import { CartcountComponent } from './pages/cartcount/cartcount.component';
import { ComparecountComponent } from './pages/comparecount/comparecount.component';
import { SafePipe } from './pipes/safe.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { WishlistcountComponent } from './pages/wishlistcount/wishlistcount.component';
import { PackageCountComponent } from './pages/packagecount/packagecount.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentsucessComponent } from './pages/paymentsucess/paymentsucess.component';
import { CustomcurrencyPipe } from './pipes/customcurrency.pipe';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ServicesComponent,
    CategoriesComponent,
    SubcategoriesComponent,
    OffshellproductlistingComponent, 
    DimensionComponent,
    CataloguelistingComponent,
    CatalogudesigningComponent,
    CheckoutComponent,
    ProductdetailsComponent,
    CartComponent,
    CompareproductsComponent,
    ThemeforentirehouseComponent,
    LoginpageComponent,
    OtppageComponent,
    FinishpageComponent,
    BHKComponent,
    SafePipe,
    RoomsforentirehouseComponent,
    MarketingvideoComponent,
    PageNotFoundComponent,
    BranchloginComponent,
    ElementlistComponent,
    LogoutComponent,
    PaymentComponent,
    PaymentsucessComponent,
    WelcomepageComponent,
    CartcountComponent,
    ComparecountComponent,
    FilterPipe,
    WishlistComponent,
    WishlistcountComponent,
    PackageCountComponent,
    CustomcurrencyPipe,  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Alias,
    IconsModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    Ng5SliderModule,
    NgxGalleryModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    DemoMaterialModule,
    TooltipModule ,
    MatSidenavModule,
    LightboxModule,
    HttpClientModule, 
    EmbedVideo.forRoot(),
    SidebarModule.forRoot(),
  
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  providers: [AppComponent, BnNgIdleService,
      {  provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }