import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { DataService } from '../data.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-otppage',
  templateUrl: './otppage.component.html',
  styleUrls: ['./otppage.component.css']
})

export class OtppageComponent implements OnInit {

    cust_mob_num: any;
    custid: number;
    public form = {
        cust_id: localStorage.getItem('cust_id'),
        otp: null
    };

    public form1 = {
        cust_id: localStorage.getItem('cust_id')
    }

    public otpError: boolean = false;
    public resendLoading: boolean = false;
    public waitNoMoreLoading: boolean = false;
    public resendOtpSuccess: boolean = false;

    goback() {
        this._location.back();
    }

    constructor(
        private router: Router, 
        private accountService: AccountService,
        private dataService: DataService,
        private _location: Location
    ) {}

    ngOnInit() {
        this.dataService.getMobileNumber(this.form1).subscribe(
            data =>{
                this.cust_mob_num = data[0]['mobile_no'];
            }
        );
    }

    resendOtp() {
        this.otpError = false;
        this.resendLoading = true;
        this.resendOtpSuccess = false;
        this.accountService.resendCustomerOtp({mobile:this.cust_mob_num}).subscribe(
            data => this.handleResendResponse(data),
            // error => this.handleError(error),
            error => {this.resendLoading = false;},
        );
    }

    verifyOtp() {
        this.otpError = false;
        this.waitNoMoreLoading = true;
        this.resendOtpSuccess = false;
        this.accountService.verifyCustomerOtp(this.form).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error), //remove the comment to activate the otp service perfectly
        );
    }

    handleResendResponse(data) {
        this.resendLoading = false;
        this.resendOtpSuccess = true;
    }

    handleResponse(data) {
        localStorage.setItem('cust_otp_verified', 'true');
        this.router.navigateByUrl('/marketingvideo');
    }

    handleError(error) {
        this.otpError = true;
        this.waitNoMoreLoading = false;
        // console.log(error);
    }
}