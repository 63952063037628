import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit {
  vimeo_iframe_html: any;
  getcount:any;
  vimeoUrl = 'https://vimeo.com/188654636';

  youtubeUrl = 'https://www.youtube.com/watch?v=iHhcHTlGtRs';
  dailymotionUrl =
    'https://www.dailymotion.com/video/x20qnej_red-bull-presents-wild-ride-bmx-mtb-dirt_sport';
 
  vimeoId = '188654636';
  youtubeId = 'iHhcHTlGtRs';
  dailymotionId = 'x20qnej';
  getcountproducts: any;
 
  constructor(private embedService: EmbedVideoService) {
    this.vimeo_iframe_html = this.embedService.embed(this.vimeoUrl,
      {
        query: { portrait: 0, color: '333',
        background:true, autoplay:true, loop:true, byline:true , title:true },
        attr: { width: 400, height: 200}
      });
  }


  ngOnInit() {
  
  }

}
