import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Cart } from '../cartcount/cartcount.model';
import { Compare } from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Options } from 'ng5-slider';

@Component({
	selector: 'app-cataloguelisting',
	templateUrl: './cataloguelisting.component.html',
	styleUrls: ['./cataloguelisting.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CataloguelistingComponent implements OnInit {
	prodForm: FormGroup;
	layoutimages: any;
	id: any;
	CatId: number;
	catlist: any;
	Themes: any;
	themeStyle: any = "col-lg-4";
	twoSelect: boolean = false;
	nineSelect: boolean = true;
	@Input('compare') compare: Compare;
	@Input('wishlist') wishlist: Wishlist;
	closeResult: string;
	logout: any;
	@Input() cart: Cart;
	public minValue = 0;
	public maxValue = 0;
  options: Options = {
    floor: 0,
    ceil: 0
  };
	customOptions: OwlOptions = {
		loop: false,
		autoplay: false,
		center: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		items: 1,
		margin: 10,
		nav: true,
		smartSpeed: 3000,
		dots: false,
		navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],

		responsive: {
			0: {
				items: 1,
				nav: true
			},
			640: {
				items: 1,
				nav: true
			},
			768: {
				items: 1,
				nav: true
			},
			991: {
				items: 1,
				nav: true
			},

			1024: {
				items: 1,
				nav: true
			},

			1280: {
				items: 1,
				nav: true
			}

		}

	}
	serviceType: any;
	queryString: string;

	goback() {
		this._location.back();
	}

	constructor(

		private formBuilder: FormBuilder, private dataService: DataService, private router: Router,
		private route: ActivatedRoute, private _location: Location

	) { }

	ngOnInit() {
		this.route.queryParams
		.subscribe(params => {
		  this.queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		}
	  );
    let id = localStorage.getItem("selectedTheme");
		this.prodForm = this.formBuilder.group({
			element: [id],
			element1: ['all']
		});
		this.getThemes();
    this.changeLayouts(id)		
		// this.id=this.route.snapshot.params.id;
		this.layoutimages = JSON.parse(localStorage.getItem('subcatalogueListing'));
		// 	var DimensionDetails = JSON.parse(localStorage.getItem("singleRoom"));
		// 	var LayoutID = { LayoutID:this.id};
		// 	var length = { length:this.layoutimages.length};
		// 	var height = { breadth:this.layoutimages.length};
		// 	var breadth = { height:this.layoutimages.height};
		// 	var shape = { height:this.layoutimages.shape};

		//        DimensionDetails.push(LayoutID, length,breadth, height, shape);
		//        localStorage.setItem("singleRoom", JSON.stringify(DimensionDetails));
		//        var result = JSON.parse(localStorage.getItem("singleRoom"));
		//        console.log(result);
	}

	get element() {
		return this.prodForm.controls['element'];
	}

	get element1() {
		return this.prodForm.controls['element1'];
	}

	getThemes() {
		this.serviceType = localStorage.getItem("serviceType");
		if (this.serviceType == 1) {
			this.CatId = parseInt(localStorage.getItem("selectedEntireCat"));
		}
		else if (this.serviceType == 2) {
			this.CatId = parseInt(localStorage.getItem("selectedSingleCat"));
		}
		this.dataService.getThemes(`${this.CatId}?${this.queryString}`).subscribe(res => {
			this.Themes = res;
			console.log(this.Themes);
		});
	}

	changeToFour() {
		this.twoSelect = true;
		this.nineSelect = false;
		this.themeStyle = "col-lg-6";

	}
	changeToNine() {
		this.nineSelect = true;
		this.twoSelect = false;
		this.themeStyle = "col-lg-4";
	}
	changeToOne() {
		this.twoSelect = false;
		this.nineSelect = false;
		this.themeStyle = "col-lg-12";
	}

	public sub_id = '';
	public catlistId = [];
	public dimensions = [];
	changeLayouts(id, type=false, price = false) {
		if(id!==""){
			// this.prodForm.patchValue({element1: ''});
			this.catlistId = []
			this.sub_id = id;
		let catlist_data = []
		let catDimensions = JSON.parse(localStorage.getItem("catalogueListing"));
		this.catlist = JSON.parse(localStorage.getItem("catalogueListing"));
		if(type == true) {
			for (var i = 0; i < this.catlist.length; i++) {
				if(id == this.catlist[i].dimensions) {
					 catlist_data.push(this.catlist[i]);
				}else{
					if (this.catlist[i].t_mst_sub_cat_id != id) {
						this.catlist.splice(i, 1);
						i--;
					}				
				}
			}			
			// this.catlistId = [...new Set(catlist_data.map(x => x.dimensions))];
		}else{
			for (var i = 0; i < this.catlist.length; i++) {
					if (this.catlist[i].t_mst_sub_cat_id != id) {
						this.catlist.splice(i, 1);
						i--;
					}
			}			
		}
		if(price == true) {
			this.catlist = this.catlist.filter((item: any) => {
			    return Number(item.price) >= this.minValue &&
			           Number(item.price) <= this.maxValue;
			});
		}else{
			this.minValue = Math.round(Math.min(...this.catlist.map(item => item.price)));
			this.maxValue = Math.round(Math.max(...this.catlist.map(item => item.price)));
	    this.options= {
	      floor: this.minValue,
	      ceil: this.maxValue
	    };
		}
		this.dimensions = this.catlist
			this.catlistId = [...new Set(this.dimensions.map(x => x.dimensions))];
		localStorage.setItem("subcatalogueListing", JSON.stringify(this.catlist));
		this.layoutimages = JSON.parse(localStorage.getItem('subcatalogueListing'));
		console.log(this.catlist)
		//this.router.navigateByUrl('/cataloguelisting');
	}
}

	public catlist_data = [];
	public selected_dimension = 'all'
changeLayouts1(id, type=false, price = false) {
			this.catlist_data = [];
			this.selected_dimension = id;
			if(id == 'all'){
				this.changeLayouts(this.sub_id, false, true);
				return;
			}
				let categoryData = JSON.parse(localStorage.getItem("catalogueListing"))
			for (var i = 0; i < categoryData.length; i++) {
				if((id == categoryData[i].dimensions) && (categoryData[i].t_mst_sub_cat_id == this.sub_id)) {
					 this.catlist_data.push(categoryData[i]);
				}
			}	
		this.catlist = this.catlist_data;
		if(price == true) {
			this.catlist = this.catlist.filter((item: any) => {
			    return Number(item.price) >= this.minValue &&
			           Number(item.price) <= this.maxValue;
			});
		}else{
			this.minValue = Math.round(Math.min(...this.catlist.map(item => item.price)));
			this.maxValue = Math.round(Math.max(...this.catlist.map(item => item.price)));
	    this.options= {
	      floor: this.minValue,
	      ceil: this.maxValue
	    };
		}
		localStorage.setItem("subcatalogueListing", JSON.stringify(this.catlist));
		this.layoutimages = JSON.parse(localStorage.getItem('subcatalogueListing'));
		// console.log(this.catlist)
}

  valueChange(value: number): void {
		this.changeLayouts1(this.selected_dimension, false, true)
  }

	popup(){
		Swal.fire({
			title: 'Move to Wishlist?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'YES',
			confirmButtonColor: 'primary',
		  }).then((check) => {
		   
		  })
	  }

	  log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }

}
