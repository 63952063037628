import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-branchlogin',
  templateUrl: './branchlogin.component.html',
  styleUrls: ['./branchlogin.component.css']
})

export class BranchloginComponent implements OnInit {

  public form = {
      email: null,
      password: null
  };

  public isSubmit: boolean = false;
  public loginError: boolean = false;
  public returnUrl: string;
  public loading: boolean = false;
  fieldTextType: boolean;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private tokenService: TokenService
  ) {}

  ngOnInit() {
      this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/landing';
  }

  doLogin() {
      this.isSubmit = true;
      this.loading = true;

      this.accountService.login(this.form).subscribe(
          data => this.handleResponse(data),
          error => this.handleError(error),
      );
  }

  handleResponse(data) {
      this.loginError = false;
      this.tokenService.handle(data.access_token);
      this.accountService.changeAuthStatus(true);
      this.router.navigateByUrl(this.returnUrl);
  }


  handleError(error) {
      this.loading = false;
      this.loginError = true;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}