import { Component, OnInit,Input,ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Bluetooth } from 'angular-feather/icons';
@Component({
  selector: 'app-bhk',
  templateUrl: './bhk.component.html',
  styleUrls: ['./bhk.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BHKComponent implements OnInit {
  @Input('compare') compare: Compare;
  @Input('wishlist') wishlist: Wishlist;
  @Input() cart:Cart;
  logout:any;
  public slides = [
      { id:"1" , name:"1 BHK" },
      { id:"2" , name:"2 BHK"},
      { id:"3" , name:"3 BHK" },
      { id:"4" , name:"4 BHK"},
     
    ];

    customOptions: OwlOptions = {
      loop:true,
      autoplay:false,
      center: true,
      items:2,
      margin:10,
      nav:true,
      smartSpeed:3000,
      dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive:{
          0:{
            items:1
          },
          640:{
            items:2
          },			
          768:{
            items:2
          },		
          991:{
            items:3
          },
          
          1024:{
            items:3
          },
          
          1280:{
            items:3
          }		
        }
      }
  bhk_id: string;
    
  constructor(
    private router: Router,
    private _location: Location,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    localStorage.setItem("serviceType",'1');
    // this.bhk_id=localStorage.getItem("selectedBhk");
    localStorage.removeItem("selectedBhk");
    localStorage.removeItem("entireHouseStatus");
    localStorage.removeItem("entireHouseAmounts");
    localStorage.removeItem("entireHouseImages");
    localStorage.removeItem("entireHouseCart");
  }
  goback() {
    this._location.back();
}

  selectBhk(id){
    localStorage.removeItem('selectedBhk');
    localStorage.setItem("selectedBhk",id);
    this.router.navigateByUrl('/roomsforentirehouse');
  }
  popup()
  {
    Swal.fire({
      imageUrl: 'assets/images/logo.png',
      title: 'Please contact customer care for further information.',
      width: 600,
      imageWidth: 120,
      showClass: {
        popup: 'swal-wide animated fadeInDown faster'
      },
   
      hideClass: {
        popup: 'swal-cart-wide animated fadeOutUp faster'
      },
      showConfirmButton: false,
     showCloseButton: true,
     showCancelButton: false,
      padding: '3em',
      
      background: 'url(assets/images/background/welcombg.jpg)no-repeat center',
    })
  }

  openVerticallyVideoCentered(content2)
  {
    this.modalService.open(content2,
    {
      centered: true,
      size: 'lg',
      backdropClass: 'light-blue-backdrop',
      windowClass: 'fadeInDown dark-modal',
     
    });
  }

  
log_out()
{
  Swal.fire({
    title: 'Are you sure you want to logout?',
    text: "Changes you made may not be saved",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.isConfirmed) {
      this.router.navigateByUrl('/logout');
    }
  })
}
}