import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TokenService } from '../../services/token.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
  	private router: Router,
    private accountService:AccountService,
    private tokenService:TokenService) { }

  ngOnInit() {
  	this.tokenService.remove();
    this.accountService.changeAuthStatus(false);
    this.router.navigateByUrl('/loginpage');
  }

}
