import { Component, OnInit,Input } from '@angular/core';
import { Compare} from './comparecount.model';
import { DataService } from '../data.service';

@Component({
  selector: 'app-comparecount',
  templateUrl: './comparecount.component.html',
  styleUrls: ['./comparecount.component.css']
})
export class ComparecountComponent implements OnInit {
  cust_id:any;
  @Input('compare') compare: Compare;
  constructor(private dataService: DataService)
  {
   
  }

  ngOnInit() {
    var x = JSON.parse(localStorage.getItem("compareproductid"));
    this.compare=new Compare();
    if(x!=null){
      this.compare.comparecount = x.length;
    }
    else{
      this.compare.comparecount=0;
    }
}

  }



