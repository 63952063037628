import { Component, OnInit, Input } from '@angular/core';
import { Wishlist } from './wishlistcount.model';
import { DataService } from '../data.service';

@Component({
  selector: 'app-wishlistcount',
  templateUrl: './wishlistcount.component.html',
  styleUrls: ['./wishlistcount.component.css']
})
export class WishlistcountComponent implements OnInit {
  cust_id:any;
@Input('wishlist') wishlist:Wishlist;

constructor(private dataService: DataService)
{
}
  ngOnInit() {
    this.wishlist=new Wishlist(); 
    this.cust_id = parseInt(localStorage.getItem("cust_id"));
    this.dataService.getWishlistcountProducts(this.cust_id).subscribe(res =>
    {
      this.wishlist.wishlistcount = Object.keys(res).length;
    });
  }
}
