import { Component, OnInit, Input } from '@angular/core';
import { Cart } from './cartcount.model';
import { DataService } from '../data.service';

@Component({
  selector: 'app-cartcount',
  templateUrl: './cartcount.component.html',
  styleUrls: ['./cartcount.component.css']
})
export class CartcountComponent implements OnInit {
  cust_id:any;
@Input('cart') cart:Cart;

constructor(private dataService: DataService)
{
}

  ngOnInit() {
    this.cart=new Cart(); 
    this.cust_id = parseInt(localStorage.getItem("cust_id"));
    this.dataService.getcountProducts(this.cust_id).subscribe(res =>
    {
      this.cart.cartcount = res;
    });
  }
}
