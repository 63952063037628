import { Component,NgModule, Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: Array<any>, filter: {[key: string]: any },arrayValues): Array<any> {
      if(filter){        
        let  filteredData=[];
         for(let i = 0; i < filter.BHK.length; i++){
   let slides = items.filter((product: any) =>
   product.BHK.toString().indexOf(filter.BHK[i].toString()) === 0);
   for(let j = 0; j < slides.length; j++){
      filteredData.push(slides[j]);
   } 
 }   
 return   filteredData            
    }else{
        let notMatchingField=items;
        return notMatchingField
      }       
    }
}