import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { DataService } from '../data.service';
import { WindowRefService } from '../window-ref.service';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/observable/interval';
import { Observable } from 'rxjs/';
import {Location} from '@angular/common';
import config from '../../config/config';
export interface serverFieldErrors {
    name:string;
    lastName:string;
    email:string;
    number:string;
    address:string;
    amount:string;
}
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  providers: [WindowRefService]
})
export class PaymentComponent implements OnInit {
  obs: any;
  custid: any;
  categoryForm: FormGroup;
  public isSubmit: boolean = false;
  public loading: boolean = false;
  public payDetails: any;
  unique_id: any;
  packages: any;
  public serverFieldErrors: serverFieldErrors = {
      name: '',
      lastName: '',
      email: '',
      number: '',
      address: '',
      amount: '',
  };
  // dataService: any;

  constructor(
      private formBuilder: FormBuilder,
      private dataService: DataService,
      private winRef: WindowRefService,
      private router: Router,
      private _location: Location,
  ) {}


  ngOnInit() {

      this.dataService.getPackages().subscribe(res => {
          this.packages = res;
      });

      this.custid = localStorage.getItem('cust_id');
      //console.log(this.custid);

      this.unique_id = this.custid + '_' + Math.random().toString(36).substr(2, 9);
      // console.log(this.unique_id);
      const reg = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
      // const ver = '/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/';
      this.categoryForm = this.formBuilder.group({
          name: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.pattern(reg)]],
          number: ['', Validators.required],
          address: [''],
          amount: ['', Validators.required]
      });
  }
  goback() {
      this._location.back();
  }
  get name() {
      return this.categoryForm.controls['name'];
  }
  get lastName() {
      return this.categoryForm.controls['lastName'];
  }
  get email() {
      return this.categoryForm.controls['email'];
  }
  get number() {
      return this.categoryForm.controls['number'];
  }
  get address() {
      return this.categoryForm.controls['address'];
  }
  get amount() {
      return this.categoryForm.controls['amount'];
  }

  payWithRazor(val) {
      console.log(this.payDetails);
      const options: any = {
          key: this.payDetails['razorpay_id'],
          amount: this.payDetails['amount'], // amount should be in paise format to display Rs 1255 without decimal point
          currency: this.payDetails['currency'],
          name: this.payDetails['name'], // company name or product name
          lastName: this.payDetails['lastName'], // company name or product name
          description: this.payDetails['description'], // product description
          image: './assets/logo.png', // company logo or product image
          order_id: this.payDetails['orderId'], // order_id created by you in backend
          modal: {
              // We should prevent closing of the form when esc key is pressed.
              escape: false,
          },
          notes: {
              // include notes if any
          },
          theme: {
              color: '#0c238a'
          }
      };
      options.handler = ((response, error) => {
          options.response = response;
          // console.log(response);
          // console.log(options);
          // call your backend api to verify payment signature & capture transaction
          this.dataService.paymentComplete(response).subscribe(
              res => {
                  if (res === 1) {
                      document.getElementById('navigate').click();
                  }
              });
      });



      options.modal.ondismiss = (() => {
          // handle the case when user closes the form while transaction is in progress
          console.log('Transaction cancelled.');
      });
      const rzp = new this.winRef.nativeWindow.Razorpay(options);
      rzp.open();
  }

  goProd() {
      window.location.href = `${config.BASE_URL}loginpage`;
  }

  onFormSubmit() {
      this.isSubmit = true;
      this.loading = true;

      if (this.categoryForm.invalid) {
          this.loading = false;
          return;
      }

      var catFormData = new FormData();
      const headers = new HttpHeaders();

      headers.append('Content-Type', 'multipart/form-data');
      var firstname = this.categoryForm.value.name;
      var lastname = this.categoryForm.value.lastName;
      var fullname = firstname + ' ' + lastname;
      catFormData.append('unique_id', this.unique_id);
      catFormData.append('t_mst_cust_id', this.custid);
      catFormData.append('t_mst_pack_id', this.categoryForm.value.amount);
      catFormData.append('name', fullname);
      catFormData.append('email', this.categoryForm.value.email);
      catFormData.append('number', this.categoryForm.value.number);
      catFormData.append('address', this.categoryForm.value.address);
      // catFormData.append('amount', this.categoryForm.value.amount);

      // console.log(catFormData);
      this.dataService.initiatePayment(catFormData, headers).subscribe(data => {
          this.isSubmit = false;
          this.payDetails = data;
          this.payWithRazor(this.payDetails);
          //document.getElementById('rzp-button1').click();
          // this.obs = Observable.interval(5000).subscribe((val) => {
                  this.dataService.getPaymentStatus(catFormData).subscribe(
                      data2 => {
                          // console.log(data2);
                          if (data2 == 'paid') {
                              document.getElementById('navigate').click();
                          }
                      }
                  );
              // });
      });
  }
}