import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { Cart } from '../cartcount/cartcount.model';
import { AlertService } from '../../services/alert.service';
import { Compare } from '../comparecount/comparecount.model';
@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css'],
  encapsulation: ViewEncapsulation.None

})

export class ProductdetailsComponent implements OnInit {
  @Input() cart: Cart;
  @Input() compare: Compare;
  viewmoreinfo: any = {};
  logout: any;
  id: any;
  prodid: any;
  elemid: any;
  cust_id: any;
  element_id: any;
  compareelementid: any;
  offshelf: any = {};
  t_mst_cust_id: any;
  getcount: Object;
  CompareProducts: any;
  compareproductid: any;
  cartcount: any;
  comparecount: any;
  videourl: any;
  videoId: any;
  iframeMarkup: string;
  convertlink: string;
  attrs: any;
  variants: any;
  image: any;
  constructor(public modalService: NgbModal, private dataService: DataService,
    private route: ActivatedRoute, private _location: Location, private router: Router,
    private alertService: AlertService
  ) {
    this.id = this.route.snapshot.params.id;
  }


  openVerticallyVideoCentered(content2) {
    this.modalService.open(content2,
      {
        centered: true,
        size: 'lg',
        backdropClass: 'light-blue-backdrop',
        windowClass: 'dark-modal'
      });
  }

  goback() {
    this._location.back();
  }
  getProductDetailsById() {
    this.dataService.getMoreInfo(this.id).subscribe(res => {
      this.viewmoreinfo = res;
      this.image = this.viewmoreinfo.image;
      this.attrs = this.viewmoreinfo.attrs;
      this.variants = this.viewmoreinfo.variants;
      this.videourl = this.viewmoreinfo.url
      if (this.videourl != null) {
        function getId(videourl) {
          const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
          const match = videourl.match(regExp);
          return (match && match[2].length === 11)
            ? match[2]
            : null;
        }
        this.videoId = getId(this.viewmoreinfo.url);
        this.iframeMarkup = 'https://www.youtube.com/embed/'
          + this.videoId;
        this.convertlink = this.iframeMarkup
      }
    }


    );
  }

  getcountproducts() {
    this.cust_id = parseInt(localStorage.getItem("cust_id"));
    this.dataService.getcountProducts(this.cust_id).subscribe(res => {
      this.getcount = res;
    })
  }

  ngOnInit() {
    document.onreadystatechange = function () {
      let desc1 = document.getElementsByClassName('fadeOut') as HTMLCollectionOf<HTMLElement>;
      let desc2 = document.getElementsByClassName('pd-block') as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < desc1.length; i++) {
        let element = desc1[i];
        let elem = desc2[i];
        if (document.readyState !== "complete") {
          elem.style.visibility = "hidden";
          element.style.visibility = "visible";
        } else {
          element.style.display = "none";
          elem.style.visibility = "visible";
        }
      }
    };
    this.getProductDetailsById();
    this.getcountproducts();
    this.updatecart();
    this.updatecompare();

  }
  updatecompare() {
    var x = JSON.parse(localStorage.getItem("compareproductid"));
    this.compare = new Compare();
    if (x != null) {
      this.compare.comparecount = x.length;
      this.comparecount = this.compare.comparecount
    }
    else {
      this.compare.comparecount = 0;
      this.comparecount = this.compare.comparecount
    }
  }
  updatecart() {
    this.cart = new Cart();
    this.cust_id = parseInt(localStorage.getItem("cust_id"));
    this.dataService.getcountProducts(this.cust_id).subscribe(res => {
      this.cart.cartcount = res;
      this.cartcount = this.cart.cartcount
    });
  }

  offshellsubmitToCart() {
    this.offshelf.t_mst_cust_id = localStorage.getItem("cust_id");
    this.offshelf.t_mst_elm_id = this.viewmoreinfo.element_id;
    this.offshelf.t_mst_product_id = this.id;
    this.offshelf.base_price = this.viewmoreinfo.base_price;
    this.offshelf.tax = this.viewmoreinfo.tax;

    this.dataService.addTocartoffshelf(this.viewmoreinfo, this.offshelf).subscribe(data => {
      this.updatecart();
      Swal.fire({
        title: 'Added to cart',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Go to cart',
        confirmButtonColor: 'primary',
        cancelButtonText: 'Stay here',
      }).then((check) => {
        if (check.value) {
          this.router.navigateByUrl('/cart');
        }
      })
    });

  }

  compareproducts() {
    var x = JSON.parse(localStorage.getItem("compareproductid"));
    var elemid = this.viewmoreinfo.element_id;
    var prodid = this.viewmoreinfo._id;
    var check = 0; var sameprod = 0;
    if (localStorage.getItem("replaceId") != null) {
      var y = JSON.parse(localStorage.getItem('compareelementid'));
      if (y != elemid) {
        Swal.fire({
          title: 'different element product is there',
          text: 'If You Want To Replace Different element!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, replace it!',
          cancelButtonText: 'No, keep it',
          confirmButtonColor: 'red',
          cancelButtonColor: 'green'
        }).then((check) => {
          if (check.value) {
            localStorage.removeItem("replaceId");
            localStorage.removeItem("compareproductid");
            localStorage.removeItem("compareelementid");
            var pid = [prodid];
            localStorage.setItem("compareproductid", JSON.stringify(pid));
            var elementid = [elemid];
            localStorage.setItem("compareelementid", JSON.stringify(elementid));
            Swal.fire({
              title: 'Yes Compared!',
              text: 'Your Previous Product has been deleted.',
              icon: 'success',
              confirmButtonText: 'Okay',
              confirmButtonColor: 'Green'
            }).then(function () {

              window.location.href = "/compareproducts";
            })
            this.updatecompare();
          } else if (check.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your imaginary file is safe :)',
              'error'
            )
          }
        })
      }



      else {
        var x = JSON.parse(localStorage.getItem('compareproductid'));
        var replaceindex = localStorage.getItem("replaceId");
        var checkpidexists = x.findIndex(id => id == prodid);
        if (checkpidexists == -1) {
          x.splice(replaceindex, 1, prodid);
          localStorage.setItem("compareproductid", JSON.stringify(x));
          //this.alertService.success('Welcome', 'Welcome back.');
          localStorage.removeItem("replaceId");
          Swal.fire({
            title: 'Product Is Added In Compared List',
            icon: 'success',
            cancelButtonText: 'No, keep it',
            cancelButtonColor: 'green'
          });

        }


        else {

          Swal.fire('This product is already in compare ');


        }


      }






    }
    else {
      if (localStorage.getItem("compareproductid") === null && localStorage.getItem("compareelementid") === null) {
        var pid = [prodid];
        localStorage.setItem("compareproductid", JSON.stringify(pid));
        var elementid = [elemid];
        localStorage.setItem("compareelementid", JSON.stringify(elementid));

        Swal.fire({
          title: 'Product Is Added In Compared List',
          icon: 'success',
          cancelButtonText: 'No, keep it',
          cancelButtonColor: 'green'
        });
        this.updatecompare();
      }
      else {
        var redirectcart = 0;
        var myObj_deserialized1 = JSON.parse(localStorage.getItem("compareelementid"));
        myObj_deserialized1.forEach(function (value) {
          if (value == elemid) {
            var comparepid2 = JSON.parse(localStorage.getItem("compareproductid"));
            if (comparepid2.length < 3) {
              comparepid2.forEach(function (value1) {
                if (value1 == prodid) {

                  check = 0;
                  sameprod = 1;
                }
                else {
                  check = 1;

                }
              });
            }
            else {
              Swal.fire('You cant compare more than 3 products at the same time.');
            }
          }
          else {
            Swal.fire({
              title: 'different element product is there',
              text: 'You will not be able to recover this imaginary file!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes,Remove it!',
              cancelButtonText: 'No, Add it',
              confirmButtonColor: 'red',
              cancelButtonColor: 'green'
            }).then((check) => {
              if (check.value) {

                localStorage.removeItem("compareproductid");
                localStorage.removeItem("compareelementid");
                var pid = [prodid];
                localStorage.setItem("compareproductid", JSON.stringify(pid));
                var elementid = [elemid];
                localStorage.setItem("compareelementid", JSON.stringify(elementid));
                Swal.fire({
                  title: 'Yes Compared!',
                  text: 'Your Previous Product has been deleted.',
                  icon: 'success',
                  confirmButtonText: 'Okay',
                  confirmButtonColor: 'Green'
                }).then(function () {

                  window.location.href = "/compareproducts";
                })
              } else if (check.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                  'Cancelled',
                  'Your Previous Product  is there :)',
                  'error'
                )
              }
            })
          }
          // alert(sameprod);
          if (sameprod == 1) {
            Swal.fire(
              {
                title: 'same product is there',
                icon: 'error',

              }
            );
          }
          // else if(sameprod == 2){
          //   this.updatecompare();  
          // } 
          else if (check == 1) {
            var comparepid3 = JSON.parse(localStorage.getItem("compareproductid"));
            comparepid3.push(prodid);
            localStorage.setItem("compareproductid", JSON.stringify(comparepid3));

            Swal.fire({
              title: 'Product Is Added In Compared List',
              icon: 'success',
              cancelButtonText: 'No, keep it',
              cancelButtonColor: 'green'
            });
            check = 0;
            redirectcart = 1

          }
        });
        if (redirectcart == 1) {
          this.updatecompare();
        }
      }
    }
  }

  
log_out()
{
  Swal.fire({
    title: 'Are you sure you want to logout?',
    text: "Changes you made may not be saved",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.isConfirmed) {
      this.router.navigateByUrl('/logout');
    }
  })
}

}