import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { ServicesComponent } from './pages/services/services.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubcategoriesComponent } from './pages/subcategories/subcategories.component';
import { OffshellproductlistingComponent } from './pages/offshellproductlisting/offshellproductlisting.component';
import { DimensionComponent } from './pages/dimension/dimension.component';
import { CataloguelistingComponent } from './pages/cataloguelisting/cataloguelisting.component';
import { CatalogudesigningComponent } from './pages/catalogudesigning/catalogudesigning.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ProductdetailsComponent } from './pages/productdetails/productdetails.component';
import { CartComponent } from './pages/cart/cart.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { WishlistcountComponent } from './pages/wishlistcount/wishlistcount.component';
import { CompareproductsComponent } from './pages/compareproducts/compareproducts.component';
import { ThemeforentirehouseComponent } from './pages/themeforentirehouse/themeforentirehouse.component';
import { LoginpageComponent } from './pages/loginpage/loginpage.component';
import { OtppageComponent } from './pages/otppage/otppage.component';
import { FinishpageComponent } from './pages/finishpage/finishpage.component';
import { BHKComponent } from './pages/bhk/bhk.component';
import { RoomsforentirehouseComponent } from './pages/roomsforentirehouse/roomsforentirehouse.component';
import { MarketingvideoComponent } from './pages/marketingvideo/marketingvideo.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BranchloginComponent } from './pages/branchlogin/branchlogin.component';
import { ElementlistComponent } from './pages/elementlist/elementlist.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { BeforeLoginService } from './services/before-login.service';
import { AfterLoginService } from './services/after-login.service';
import { AfterCustLoginService } from './services/after-cust-login.service';
import { WelcomepageComponent } from './pages/welcomepage/welcomepage.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentsucessComponent } from './pages/paymentsucess/paymentsucess.component';

const routes: Routes = [
	{ path: '', redirectTo:'/branchlogin', pathMatch:'full'},
	{ path: 'branchlogin', component: BranchloginComponent},
	{ path: 'landing', component: LandingComponent, canActivate: [AfterLoginService]},
	{ path: 'welcomepage', component: WelcomepageComponent, canActivate: [AfterLoginService]},
	{ path: 'loginpage', component:LoginpageComponent, canActivate: [AfterLoginService]},
	{ path: 'otppage', component:OtppageComponent, canActivate: [AfterLoginService]},
	{ path: 'marketingvideo', component: MarketingvideoComponent, canActivate: [AfterLoginService]},
	{ path: 'services', component:ServicesComponent, canActivate: [AfterLoginService]},
	//single room
	{ path: 'categories', component:CategoriesComponent, canActivate: [AfterLoginService]},
	{ path: 'subcategory/:id', component:SubcategoriesComponent, canActivate: [AfterLoginService]},
	{ path: 'dimension/:id', component:DimensionComponent, canActivate: [AfterLoginService]},
	{ path: 'cataloguelisting', component:CataloguelistingComponent, canActivate: [AfterLoginService]},
	{ path: 'cataloguedesigning/:id', component:CatalogudesigningComponent, canActivate: [AfterLoginService]},
	//browse products
	{ path: 'elementlist', component: ElementlistComponent, canActivate: [AfterLoginService]},
	{ path: 'offshellproductlisting/:id', component:OffshellproductlistingComponent, canActivate: [AfterLoginService]},
	{ path: 'productdetails/:id', component:ProductdetailsComponent, canActivate: [AfterLoginService]},
	//Entire house
	{ path: 'BHK', component:BHKComponent, canActivate: [AfterLoginService]},
	{ path: 'roomsforentirehouse', component: RoomsforentirehouseComponent, canActivate: [AfterLoginService] },
	{ path: 'themeforentirehouse/:id', component:ThemeforentirehouseComponent, canActivate: [AfterLoginService]},
	
	{ path: 'cart', component:CartComponent, canActivate: [AfterLoginService] },
	{ path: 'wishlist', component:WishlistComponent, canActivate: [AfterLoginService] },
	{ path: 'checkout', component:CheckoutComponent, canActivate: [AfterLoginService] },
	{ path: 'compareproducts', component:CompareproductsComponent, canActivate: [AfterLoginService] },
	{ path: 'finishpage', component:FinishpageComponent, canActivate: [AfterLoginService]},
	{ path: 'pagenotfound', component: PageNotFoundComponent, data: {animation: 'Checkout'} },
	{ path: 'payment', component:PaymentComponent, canActivate: [AfterLoginService]},
	{ path: 'paymentsucess', component:PaymentsucessComponent},
	{ path: 'logout', component: LogoutComponent, canActivate: [AfterLoginService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]  
})
export class AppRoutingModule { }
