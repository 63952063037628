import { ChangeDetectionStrategy,
  Component,
  Input, OnInit, ViewEncapsulation,ViewChild, Output, EventEmitter  } from '@angular/core';
import { Options } from 'ng5-slider';
import { DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
@Component({
  selector: 'app-offshellproductlisting',
  templateUrl: './offshellproductlisting.component.html',
  styleUrls: ['./offshellproductlisting.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OffshellproductlistingComponent implements OnInit {
  @Input() cart:Cart;
  @Input('compare') compare: Compare;
  page:any;
  logout:any;
  cust_id: number;
  id:any;
  elementslist: any;
  filterParams:any= {};
  productlists:any;
  productsdata:any;
  eleid: any;
  prdid:any;
  params:any= {};
  productslist:any;
  minValue: number = 10;
  maxValue: number = 245400;
  options: Options = {
    floor: 0,
    ceil: 250000
  };
 
  @Output() sidenavClose = new EventEmitter();

 
 

  constructor(private dataService:DataService,
    private _location: Location,private router: Router,
		private route: ActivatedRoute ) { }

  ngOnInit() {
    localStorage.removeItem('filterEleId');
    localStorage.removeItem('filterVarId');
    localStorage.removeItem('filterVarVal');
    this.id =this.route.snapshot.params.id;
    this.getProductDetailsByEleId();
    this.getproductfilters();

  }
  getproductfilters(){
    this.params['eleid']=this.id;
    if(localStorage.getItem("filterVarId") != null || localStorage.getItem("filterVarVal") != null){
      localStorage.removeItem("filterVarId");
      localStorage.removeItem("filterVarVal");
    }
    this.dataService.getProductsoffshellFilters(this.params).subscribe(
      data => {
        this.productsdata = data;
        this.elementslist=this.productsdata.elements;
        
        // this.options= {
        //   floor: this.productsdata.least,
        //   ceil: this.productsdata.max
        // };
        if(this.productsdata.least == 0.00 && this.productsdata.max == 0.00)
        {
            this.options = {
              floor: 0,
              ceil: 2000000
            };
            this.minValue= this.productsdata.least;
            this.maxValue= this.productsdata.max;
        }
        else if(this.productsdata.least == this.productsdata.max){
            this.options = {
              floor: this.productsdata.least-1,
              ceil: this.productsdata.max 
            };
            this.minValue= this.productsdata.least-1;
            this.maxValue= this.productsdata.max;
        }
        else
        {
          this.options = {
            floor: this.productsdata.least,
            ceil: this.productsdata.max
          };
          this.minValue= this.productsdata.least;
          this.maxValue= this.productsdata.max;
        }
        this.eleid=this.id;
        this.filter();
      }
    ); 
    
  }

  // onFilterChange(eve: any, varVal, varId, eleid)
  // { 
  //   localStorage.setItem("filterEleId", eleid);
  //   if(varVal!=null && varId!=null && eleid!=null){
  //     if (eve.target.checked == true){
  //       if (localStorage.getItem("filterVarId") === null && localStorage.getItem("filterVarVal") === null)
  //       { 
  //         localStorage.setItem("filterVarId", JSON.stringify([varId]));
  //         localStorage.setItem("filterVarVal", JSON.stringify([{varVal:[varVal]}]));
  //       }
  //       else
  //       {  
  //         var myObj_deserialized1 = JSON.parse(localStorage.getItem("filterVarId"));
  //         var myObj_deserialized2 = JSON.parse(localStorage.getItem("filterVarVal"));
  //         if(myObj_deserialized1.indexOf(varId)!=-1) //same variant
  //         {
  //           var indexValue=myObj_deserialized1.indexOf(varId);
  //           var i=0;
  //           myObj_deserialized2.forEach(function(value){
  //             if(i==indexValue){
  //               if(value.varVal.indexOf(varVal)==-1){
  //                 value.varVal.push(varVal);
  //               }
  //             }
  //             i++;
  //           });
  //           localStorage.setItem("filterVarVal",JSON.stringify(myObj_deserialized2));
  //         }else //different variant
  //         {
  //           myObj_deserialized1.push(varId);
  //           localStorage.setItem("filterVarId", JSON.stringify(myObj_deserialized1));
  //           myObj_deserialized2.push({varVal:[varVal]});
  //           localStorage.setItem("filterVarVal", JSON.stringify(myObj_deserialized2));
  //         }
  //       }
  //     }
  //     else{ //remove from local storage if unchecked
  //       var myObj_deserialized1 = JSON.parse(localStorage.getItem("filterVarId"));
  //       var myObj_deserialized2 = JSON.parse(localStorage.getItem("filterVarVal"));
  //       var indexValue=myObj_deserialized1.indexOf(varId);
  //       var i=0;
  //       myObj_deserialized2.forEach(function(value){
  //         if(i==indexValue){
  //           value.varVal.splice(value.varVal.indexOf(varVal),1);
  //           if(value.varVal.length==0){
  //             myObj_deserialized1.splice(indexValue,1);
  //             myObj_deserialized2.splice(indexValue,1);
  //           }
  //         }
  //         i++;
  //       });
  //       localStorage.setItem("filterVarId", JSON.stringify(myObj_deserialized1));
  //       localStorage.setItem("filterVarVal",JSON.stringify(myObj_deserialized2));
  //     }
  //   }
    

  //   if(localStorage.getItem("filterVarId") != null || localStorage.getItem("filterVarVal") != null || localStorage.getItem("filterVarVal") != null || this.minValue != this.productsdata.least || this.maxValue != this.productsdata.max)
  //   {
  //     this.filterParams['varId'] = [];
  //     this.filterParams['varVal'] = [];
  //     this.filterParams['eleId'] =[];

  //     if (localStorage.getItem("filterVarId") != null){
  //       this.filterParams['varId'] = JSON.parse(localStorage.getItem('filterVarId'));
  //     }

  //     if (localStorage.getItem("filterVarVal") != null){
  //       this.filterParams['varVal'] = JSON.parse(localStorage.getItem('filterVarVal'));
  //     }

  //     if (localStorage.getItem("filterEleId") != null){
  //       this.filterParams['eleId'] = JSON.parse(localStorage.getItem('filterEleId'));
  //     }

  //     this.filterParams['minBug'] = this.minValue;
  //     this.filterParams['maxBug'] = this.maxValue;
  //     this.filterParams['page'] = 2;

  //     this.dataService.getFilteredData(this.filterParams).subscribe(
  //       data =>
  //       {
  //         this.productlists = data;
  //         // console.log(this.productslist);
  //       }
  //     );
  //   }
  // }
 
  goback() {
		this._location.back();
    }
    
  getProductDetailsByEleId()
  {
    this.dataService.getProductsByEleId(this.id).subscribe(res=>
      {
      this.productlists=res;
    
  
      });
  }

  onFilterChange(eve: any, varVal, varId, eleid)
  {
    localStorage.setItem("filterEleId", eleid);
    if (eve.target.checked == true){
      if (localStorage.getItem("filterVarId") === null && localStorage.getItem("filterVarVal") === null)
      { 
        localStorage.setItem("filterVarId", JSON.stringify([varId]));
        localStorage.setItem("filterVarVal", JSON.stringify([{varVal:[varVal]}]));
      }
      else
      {  
        var myObj_deserialized1 = JSON.parse(localStorage.getItem("filterVarId"));
        var myObj_deserialized2 = JSON.parse(localStorage.getItem("filterVarVal"));
        if(myObj_deserialized1.indexOf(varId)!=-1) //same variant
        {
          var indexValue=myObj_deserialized1.indexOf(varId);
          var i=0;
          myObj_deserialized2.forEach(function(value){
            if(i==indexValue){
              if(value.varVal.indexOf(varVal)==-1){
                value.varVal.push(varVal);
              }
            }
            i++;
          });
          localStorage.setItem("filterVarVal",JSON.stringify(myObj_deserialized2));
        }else //different variant
        {
          myObj_deserialized1.push(varId);
          localStorage.setItem("filterVarId", JSON.stringify(myObj_deserialized1));
          myObj_deserialized2.push({varVal:[varVal]});
          localStorage.setItem("filterVarVal", JSON.stringify(myObj_deserialized2));
        }
      }
    }
    else{ //remove from local storage if unchecked
      var myObj_deserialized1 = JSON.parse(localStorage.getItem("filterVarId"));
      var myObj_deserialized2 = JSON.parse(localStorage.getItem("filterVarVal"));
      var indexValue=myObj_deserialized1.indexOf(varId);
      var i=0;
      myObj_deserialized2.forEach(function(value){
        if(i==indexValue){
          value.varVal.splice(value.varVal.indexOf(varVal),1);
          if(value.varVal.length==0){
            myObj_deserialized1.splice(indexValue,1);
            myObj_deserialized2.splice(indexValue,1);
          }
        }
        i++;
      });
      localStorage.setItem("filterVarId", JSON.stringify(myObj_deserialized1));
      localStorage.setItem("filterVarVal",JSON.stringify(myObj_deserialized2));
    }
  }

  filter()
  { if(localStorage.getItem("filterVarId") != null || localStorage.getItem("filterVarVal") != null || localStorage.getItem("filterVarVal") != null || this.minValue != 0 || this.maxValue != 0 ) // this.minValue != this.productsdata.least || this.maxValue != this.productsdata.max
    {
      this.filterParams['varId'] = [];
      this.filterParams['varVal'] = [];
      this.filterParams['eleId'] =[];

      if (localStorage.getItem("filterVarId") != null){
        this.filterParams['varId'] = JSON.parse(localStorage.getItem('filterVarId'));
      }

      if (localStorage.getItem("filterVarVal") != null){
        this.filterParams['varVal'] = JSON.parse(localStorage.getItem('filterVarVal'));
      }

      if (localStorage.getItem("filterVarVal") != null){
        this.filterParams['eleId'] = JSON.parse(localStorage.getItem('filterEleId'));
      }
      else{
        this.filterParams['eleId'] =this.eleid;
      }

      this.filterParams['minBug'] = this.minValue;
      this.filterParams['maxBug'] = this.maxValue;
      this.filterParams['page'] = 2;
      // console.log(this.filterParams);
      this.dataService.getFilteredData(this.filterParams).subscribe(
        data =>
        {
          this.productlists = data;
        }
      );
    }
    // localStorage.removeItem('filterEleId');localStorage.removeItem('filterVarId');localStorage.removeItem('filterVarVal');
  }

  log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }

}
