import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router} from '@angular/router';
import {Location} from '@angular/common';
import { DataService } from '../data.service';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import Swal from 'sweetalert2';
import { sequence } from '@angular/animations';
import { HttpHeaders } from '@angular/common/http';
import config from '../../config/config';
@Component({
  selector: 'app-roomsforentirehouse',
  templateUrl: './roomsforentirehouse.component.html',
  styleUrls: ['./roomsforentirehouse.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RoomsforentirehouseComponent implements OnInit {
  @Input('compare') compare: Compare;
  @Input('wishlist') wishlist: Wishlist;
  @Input() cart:Cart;
  public slides;
  status: any[];
  amounts: any[];
  images: any[];
  carts: any[];
  public Data:any[];
  bhk:any={};
  name:any;
  logout:any;
  layoutPath:any;
  params:any= {};
  getFilterDetails: any;
  serviceType:any;
  catlist: any;
    
  constructor(
      private dataService:DataService, 
      private router: Router, 
      private _location: Location) { 

        this.bhk=parseInt(localStorage.getItem("selectedBhk"));
      }

  ngOnInit()
   {
    this.dataService.getRoomForEntireHouseDetails(this.bhk).subscribe(res=> 
    {
      console.log(res);
      var appendClass="";
      var x=[]; var y=[]; var amount=[]; var cart=[]; var image=[];
      this.slides=res;
      //console.log(this.slides);
      console.log(this.slides.length);
      if (localStorage.getItem("entireHouseStatus") === null)
      {
        for (var i=0; i<this.slides.length;i++)
        { 
          x.push({ sequence:i+1,unique_id: this.slides[i][i+"_id"] ,src: this.slides[i][i+"_image"] , name:this.slides[i][i+"_name"], class:appendClass,price:0});
          y.push('N');
          cart.push('0');
          amount.push('0');
          image.push('0');
          console.log(this.slides[i]._id)
        }
        
        this.status=y;
        this.amounts=amount;
        this.images=image;
        this.carts=cart;
        localStorage.setItem("entireHouseStatus",JSON.stringify(this.status));
        localStorage.setItem("entireHouseAmounts",JSON.stringify(this.amounts));
        localStorage.setItem("entireHouseImages",JSON.stringify(this.images));
        localStorage.setItem("entireHouseCart",JSON.stringify(this.carts));
      
        this.slides = x;
      }
      else
      {
        var x=[];var y=[];  var ind=0;
        var status=JSON.parse(localStorage.getItem("entireHouseStatus"));
        var amounts=JSON.parse(localStorage.getItem("entireHouseAmounts"));
        var images=JSON.parse(localStorage.getItem("entireHouseImages"));
        var data=this.slides;
        status.forEach(function (value)
        { 
          if(value=='Y'){
            appendClass="Finish";
          }
          else{
            appendClass="";
          }
          
          for (var i=0; i<data.length;i++)
          {
            var image= images[i];
            if(ind==i){
              var amount= amounts[i];
              if(image=='0')
              {
                console.log(data[i][i+"_image"]);
                x.push({ sequence:i+1,unique_id: data[i][i+"_id"] ,src: data[i][i+"_image"] ,name:data[i][i+"_name"], class:appendClass, price:amount});
              }
              else
              {
                //var path = this.getLayoutsPath(image);
                //use the below line for demo server
                x.push({ sequence:i+1,unique_id: data[i][i+"_id"] ,src:`${config.BASE_URL}webservice/images/layouts/`+image , name:data[i][i+"_name"], class:appendClass, price:amount});
                //use the below line for local host 
                //x.push({ sequence:i+1,unique_id: data[i][i+"_id"] ,src:"http://localhost:8000/images/layouts/"+image , name:data[i][i+"_name"], class:appendClass, price:amount});
              }
            }
          }
          ind++;

       });
   
       this.slides = x;
      }
  });
    
   
  }

  getLayoutsPath(image:string)
  {
      this.dataService.getLayoutPath(image).subscribe(res=>
      {
        this.layoutPath = res['imgpath'];
      });
      return this.layoutPath;
  }
   
  goback() {
    this._location.back();
}

dimensionRedirect(id,index){
  localStorage.setItem("selectedEntireCat",id);
  localStorage.setItem("selectedEntireCatIndex",index);
  const headers=new HttpHeaders();
        //this.id=this.route.snapshot.params.id;
        this.params['id']=id;
        this.params['serviceType']=localStorage.getItem("serviceType");
        this.params['basetype']=1;

        // console.log(this.params);
        if((this.params['length']!=0 && this.params['height']!=0 &&  this.params['breadth']!=0) ||  this.params['shape']!=0 ||this.params['basetype']!=0 )
        {
            this.dataService.getLayoutImagesShape(this.params).subscribe( data=> {
                this.getFilterDetails=data;
                localStorage.setItem('catalogueListing', JSON.stringify(this.getFilterDetails));
            });
           
        }

  // if(localStorage.getItem("EntireHouseTheme"))
  // {
  //   this.catlist = JSON.parse(localStorage.getItem("catalogueListing"));
  //   localStorage.setItem("selectedTheme",localStorage.getItem("EntireHouseTheme"));
  //   for(var i=0;i<this.catlist.length;i++)
  //   {
  //     if(this.catlist[i].t_mst_sub_cat_id != localStorage.getItem("EntireHouseTheme"))
  //     {
  //       this.catlist.splice(i,1);
  //       i--;
  //     }
  //   }
  //   localStorage.setItem("subcatalogueListing",JSON.stringify(this.catlist));
  //   localStorage.removeItem("EntireHouseTheme");
  // }
  // else
  //{
    this.router.navigateByUrl('/themeforentirehouse/'+id+'?length=0&breadth=0&height=0&min=11418.54&max=18618.54&shape=0');
  //}
}
alreadyAdded(){
  Swal.fire({
      title: 'This room is already added to the cart',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'OK',
      confirmButtonColor: 'primary',
    }).then((check) => {
     
    })
}


log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }

}
