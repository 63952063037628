import { Component, OnInit ,Input} from '@angular/core';
import { DataService } from '../data.service';
import {ActivatedRoute,Router} from '@angular/router';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-compareproducts',
  templateUrl: './compareproducts.component.html',
  styleUrls: ['./compareproducts.component.css']
})
export class CompareproductsComponent implements OnInit {
  @Input() cart:Cart;
  @Input('compare')compare: Compare;

  t_mst_cust_id: any;
  cust_id:any;
  compareproductid:any;
  t_mst_elm_id
  cartproductswe:any;
  params: any = {};
  productsInfo: any;
  offshelf: any = {};
  viewmoreinfo: any;
  t_mst_product_id: any;
  id: any;
  product: any;
  compareproductslist: any;
  logout:any;

  constructor( private dataService: DataService,  private route:ActivatedRoute,private _location: Location, private router: Router) { }
  goback()
  {
    this._location.back();
  }

  ngOnInit() {
    this.params['element']=JSON.parse(localStorage.getItem('compareelementid'));
    this.params['product']=JSON.parse(localStorage.getItem('compareproductid'));
    this.dataService.compareProducts(this.params).subscribe(data =>
    {  
      this.productsInfo=data;
      console.log(this.productsInfo);
    });
    this.compareproductslist=0;
    var x=JSON.parse(localStorage.getItem("compareproductid"));
    if(x!=null){
      this.compareproductslist=x.length;
    }
  }
updatecompare()
{
  var x = JSON.parse(localStorage.getItem("compareproductid"));
  this.compare=new Compare();
  if(x!=null){
    this.compare.comparecount = x.length;
  }
  else{
    this.compare.comparecount=0;
  }
}
  offshellsubmitToCart(eleid,pid,base_price,tax)
  {
  
    this.offshelf.t_mst_cust_id = localStorage.getItem("cust_id");
    this.offshelf.t_mst_elm_id =eleid;
    this.offshelf.t_mst_product_id =pid;
    this.offshelf.base_price =base_price;
    this.offshelf.tax =tax;
    this.dataService.addTocartoffshelf(this.productsInfo, this.offshelf).subscribe(data =>
    {
      var x=JSON.parse(localStorage.getItem('compareproductid'));
      var checkpidexists = x.findIndex(id => id == pid);

      if(x.length==1)
      {

        Swal.fire({
          title: 'one product is there',
          text: 'If you want to add product to cart!',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Add to Cart!',
          cancelButtonText: 'No, keep it',
          confirmButtonColor: 'red',
          cancelButtonColor: 'green'
        }).then((check) => {
          if (check.value) {
            localStorage.removeItem("compareproductid");
            localStorage.removeItem("compareelementid");
            Swal.fire(
              'Added To Cart',
              'Your Product Added To Cart.',
              'success'
              
          )
          this.router.navigateByUrl('/cart');  
          } else if (check.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Product Is Not Added :)',
              'error'
            )
          }
        })
        // Swal.fire('one product is there');
        // return false;
      }
      else
      {
        x.splice(checkpidexists,1);
        localStorage.setItem("compareproductid",JSON.stringify(x));
        this.router.navigateByUrl('/cart');  
      }
     
    });
  }
  replaceproducts(eid,pid)
  {
    var x=JSON.parse(localStorage.getItem('compareproductid'));
    var newindex = x.findIndex(id => id == pid);
    localStorage.setItem("replaceId",newindex);
    this.router.navigateByUrl('/offshellproductlisting/'+eid);
  }

  log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }
}



