import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import{ Observable, of } from 'rxjs';
import 'rxjs/add/operator/map'
import config from '../config/config';

@Injectable({
 providedIn: 'root'
})
export class DataService {
    params: any= {};
    params1: any= {};
    apiUrl: string = config.API_ENDPOINT;
    constructor(private httpclient: HttpClient) {}

    //categories
    getData() {
        return this.httpclient.get(`${this.apiUrl}/front/getCategories`)
    }
   
    //subcategories
    getCategoriesDetails(id) {
        return this.httpclient.get(`${this.apiUrl}/front/getSubcategories/` + id)
    }

    //entirehousecategories
    gethousedata() {
        return this.httpclient.get(`${this.apiUrl}/front/gethouseCategories`)
    }

    //images & hotspots
    getCatalogueDesign(params) {
        return this.httpclient.post(`${this.apiUrl}/front/showCatalogueDesign`, {
            params: params
        });
    }

    getTransitions(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getTransitions`, {
            params: params
        });
    }

    //layoutlisting
    getLayoutImagesShape(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getLayoutListing`, {
            params: params
        });
    }

    //filters
    getProductsFilters(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getProductsFilters`, {
            params: params
        });
    }

    //get filtered products and its data
    getFilteredData(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getFilteredData`, {
            params: params
        });
    }

    //product More Information
    getMoreInfo(id) {
        return this.httpclient.get(`${this.apiUrl}/front/getProductAttributeDetails/`+id)
    }

    //product More Information
    getMoreInfoPopup(id) {
        return this.httpclient.get(`${this.apiUrl}/front/getMoreInfoPopup/`+id)
    }

    getSelectedProducts(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getSelectedProducts`, {
            params: params
        });
    }

    getElementLists(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getElementLists`, {
            params: params
        });
    }

    //addToCart
    addToCart(params,layoutdetails){
        return this.httpclient.post(`${this.apiUrl}/front/addToCart`, {
            params: params,
            layoutdetails: layoutdetails
        });
    }
 
    addTocartoffshelf(params1,offshelf)
    {
        return this.httpclient.post(`${this.apiUrl}/front/addToCart`, {
           
            params1: params1,
            offshelf:offshelf
           
        });
    }

    addToWishlist(params,layoutdetails){
        return this.httpclient.post(`${this.apiUrl}/front/addToWishlist`, {
            params: params,
            layoutdetails: layoutdetails
        });
    }

    getThemes(id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getThemes/`+id);
    }

    getTransitionImage(params,layoutdetails){
        return this.httpclient.post(`${this.apiUrl}/front/getTransitionImage`, {
            params: params,
            layoutdetails: layoutdetails
        });
    }

    getTransitionalHotspotsImages(layoutid){
        return this.httpclient.post(`${this.apiUrl}/front/getTransitionalHotspotsImages`, {
            layoutid: layoutid
        });
    }

    getProductsoffshellFilters(params)
    {
        return this.httpclient.post(`${this.apiUrl}/front/getProductsoffshellFilters`, {
            params: params
        });
    }
    getProfileDetail(id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getCustomerdetails/`+id)
    }

    getAddonDetails()
    {
        const headers = new HttpHeaders();
        let obj = {"draw":1,"columns":[{"data":"id","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"name","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"desc","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom_rate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom_quantity","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom_amount","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"status","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"actions","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}}],"order":[{"column":0,"dir":"asc"}],"start":0,"length":100,"search":{"value":"","regex":false},"filters":{"status":"all","name":""}};
        return this.httpclient.post(`${this.apiUrl}/addon/getAjaxDatatableList`, obj, {headers: headers})
    }

    getServiceDetails()
    {
        const headers = new HttpHeaders();
        let obj = {"draw":1,"columns":[{"data":"id","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"name","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"description","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom/rate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"amount","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"create_date","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}}],"order":[{"column":0,"dir":"asc"}],"start":0,"length":10,"search":{"value":"","regex":false},"filters":{"status":"all","name":""}};
        return this.httpclient.post(`${this.apiUrl}/service/getAjaxDatatableList`, obj, {headers: headers})
    }

    getTermDetails()
    {
        // const headers = new HttpHeaders();
        // let obj = {"draw":1,"columns":[{"data":"id","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"name","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"description","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"uom/rate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"amount","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"create_date","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}}],"order":[{"column":0,"dir":"asc"}],"start":0,"length":10,"search":{"value":"","regex":false},"filters":{"status":"all","name":""}};
        return this.httpclient.get(`${this.apiUrl}/term/getTermDetail/1`)
    }

    getProductsByEleId(id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getProductsByEleId/`+id)
    }

    getProductDetailsById(id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getProductAttributeDetails/`+id) 
    }

    getCartProducts(cust_id) {
        return this.httpclient.get(`${this.apiUrl}/front/getCartProducts/`+cust_id)
    }

    getWishlistProducts(cust_id) {
        return this.httpclient.get(`${this.apiUrl}/front/getWishlistProducts/`+cust_id)
    }

    getcountProducts(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getcountProducts/`+cust_id)
    }

    getWishlistcountProducts(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getWishlistcountProducts/`+cust_id)
    }

    getPackagecount(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getPackageCount/`+cust_id)
    }

    getUsedcount(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getUsedDesignCount/`+cust_id)
    }

    getCustomerPackage(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/utilities/getCustomerPackage/`+cust_id)
    }

    getProductDetailsByEleId(id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getProductDetailsByEleId/`+id)
    }

    createProjectServiceLive(data,otherparams)
    {
        return this.httpclient.post(`${this.apiUrl}/front/createProjectServiceLive`, {
           data,
            otherparams: otherparams
        });
    }

    sendProjectMail(data,otherparams)
    {
        return this.httpclient.post(`${this.apiUrl}/front/sendProjectMail`, {
            data,
            otherparams: otherparams
         });
    }
    
    requestServiceLive(params,otherparams)
    {
        return this.httpclient.post(`${this.apiUrl}/front/requestServiceLive`, {
            params: params,
            otherparams: otherparams
        });
    }

    deleteFromCart(params)
    {
        return this.httpclient.post(`${this.apiUrl}/front/deleteFromCart`, {
            params: params
        });
    }

    addToCartWishlist(params)
    {
        return this.httpclient.post(`${this.apiUrl}/front/addToCartWishlist`, {
            params: params
        });
    }

    deleteFromWishlist(params)
    {
        return this.httpclient.post(`${this.apiUrl}/front/deleteFromWishlist`, {
            params: params
        });
    }

    changeQuantity(params)
    {
        return this.httpclient.post(`${this.apiUrl}/front/changeQuantity`, {
            params: params
        });
    }

    compareProducts(params)
    {
        return this.httpclient.post(`${this.apiUrl}/front/compareProducts`, {
            params: params
        });
    }

    getRoomForEntireHouseDetails(id) {
        return this.httpclient.get(`${this.apiUrl}/front/getRoomForEntireHouseDetails/`+id)
     
    }

    //Min and Max budget Amount
    getMinMaxBudget(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getMinMaxBudget`, {
            params: params
        });
    }

    getMobileNumber(params) {
        return this.httpclient.get(`${this.apiUrl}/front/getMobileNumber`, {
            params: params
        });
    }

    getDescription(params) {
        return this.httpclient.get(`${this.apiUrl}/front/getDesc`, {
            params: params
        });
    }

    getImgInfo(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getImgInfo`, {
            params: params
        });
    }

    getLayoutPath(params) {
        return this.httpclient.post(`${this.apiUrl}/front/getImgInfo`, {
            params: params
        });
    }

    initiatePayment(data, headers) {
        return this.httpclient.post(`${this.apiUrl}/payment/initiate`, data, {headers: headers});
    }

    addPackage(data, headers) {
        return this.httpclient.post(`${this.apiUrl}/payment/addPackage`, data, {headers: headers});
    }

    paymentComplete(data) {
        return this.httpclient.post(`${this.apiUrl}/payment/complete`, data);
    }

    paymentResponse(data) {
        return this.httpclient.post(`${this.apiUrl}/payment/paymentResponse`, data);
    }

    getPaymentStatus(data) {
        return this.httpclient.post(`${this.apiUrl}/payment/getPaymentStatus`, data);
    }

    getPackageCount(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getPackageCount/`+cust_id)
    }

    getUsedDesignCount(cust_id)
    {
        return this.httpclient.get(`${this.apiUrl}/front/getUsedDesignCount/`+cust_id)
    }

    checkPackage(cust_mobile)
    {
        return this.httpclient.get(`${this.apiUrl}/front/checkPackage/`+cust_mobile)
    }

    getPackages(){
        return this.httpclient.get(`${this.apiUrl}/payment/getPackages`)
    }
}
