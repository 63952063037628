import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare } from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SubcategoriesComponent implements OnInit {
  subCategories: any;
  id: any;
  desc: any;
  subcatlist: any;
  params: any = {};
  getFilterDetails: any;
  serviceType: any;
  logout: any;
  twoSelect: boolean = false;
  nineSelect: boolean = false;
  @Input() cart: Cart;
  @Input('compare') compare: Compare;
  @Input('wishlist') wishlist: Wishlist;
  customOptions: OwlOptions = {
    nav: true,
    navText: ['<i class="fa fa-angle-double-left"></i>',
      '<i class="fa fa-angle-double-right"></i>'],
    loop: true,
    autoplay: false,
    margin: 5,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    responsive: {
      0: {
        items: 1
      }
      ,
      640: {
        items: 2
      }
      ,
      768: {
        items: 2
      }
      ,
      991: {
        items: 1
      }
      ,
      1024: {
        items: 1
      }
      ,
      1280: {
        items: 1
      }
    }
  }
  themeStyle: string;
  constructor(private dataService: DataService, private route: ActivatedRoute, private _location: Location, private router: Router,) { }

  ngOnInit() {
    this.serviceType = localStorage.getItem("serviceType");
    const headers = new HttpHeaders();
    this.id = this.route.snapshot.params.id;
    localStorage.setItem("selectedSingleCat",this.id)
    console.log(this.id);
    this.params['id'] = this.id;
    this.params['serviceType'] = localStorage.getItem("serviceType");
    this.params['basetype'] = 1;

    // console.log(this.params);
    if ((this.params['length'] != 0 && this.params['height'] != 0 && this.params['breadth'] != 0) || this.params['shape'] != 0 || this.params['basetype'] != 0) {
      this.dataService.getLayoutImagesShape(this.params).subscribe(data => {
        this.getFilterDetails = data;
        localStorage.setItem('catalogueListing', JSON.stringify(this.getFilterDetails));
      });

    }
    this.getCategoryDetail();
    this.subcatlist = JSON.parse(localStorage.getItem("catalogueListing"));
  }

  goback() {
    this._location.back();
  }

  getCategoryDetail() {
    this.dataService.getCategoriesDetails(this.id).subscribe(res => {
      this.subCategories = res;
      console.log(this.subCategories);
    })
  }

  selectedSubCat(subcatid) {
    localStorage.setItem("selectedSubCat", subcatid);
    this.subcatlist = JSON.parse(localStorage.getItem("catalogueListing"));
    for (var i = 0; i < this.subcatlist.length; i++) {
      if (this.subcatlist[i].t_mst_sub_cat_id != subcatid) {
        this.subcatlist.splice(i, 1);
        i--;
      }
    }
    localStorage.setItem("subcatalogueListing", JSON.stringify(this.subcatlist));
    this.router.navigateByUrl('/cataloguelisting');
  }

  changeToFour() {
    this.twoSelect = true;
    this.nineSelect = false;
    this.themeStyle = "col-lg-6";

  }
  changeToNine() {
    this.nineSelect = true;
    this.twoSelect = false;
    this.themeStyle = "col-lg-4";
  }
  changeToOne() {
    this.twoSelect = false;
    this.nineSelect = false;
    this.themeStyle = "col-lg-12";
  }
}
