import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Swal from 'sweetalert2';
import { Cart } from '../cartcount/cartcount.model';
import { Compare } from '../comparecount/comparecount.model';
import { DataService } from '../data.service';
import { Wishlist } from '../wishlistcount/wishlistcount.model';


@Component(
{
  selector: 'app-themeforentirehouse',
  templateUrl: './themeforentirehouse.component.html',
  styleUrls: ['./themeforentirehouse.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeforentirehouseComponent implements OnInit
{
  @Input('compare') compare: Compare;
  @Input('wishlist') wishlist: Wishlist;
  @Input() cart:Cart;
  Themes: any;
  logout:any;
  bhk_id: any;
  customOptions: OwlOptions = {
    nav: true,
    navText: [
      '<i class="fa fa-long-arrow-left" aria-hidden="true"></i>',
      '<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'
    ],
    loop: true,
    autoplayTimeout:10000,
    smartSpeed:2000,
    autoplay: false,
    margin:0,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,

    dots: false,
    responsive:
    {
      0:
      {
        items: 1
      },
      640:
      {
        items: 1
      },
      768:
      {
        items: 1
      },
      991:
      {
        items: 1
      },

      1024:
      {
        items: 1
      },

      1280:
      {
        items: 1
      }

    }

  }
  id: number;
  name: any;
  desc: any;
  desc1:any;
  catlist:any;
  catArray:any;
  themeStyle:any="col-lg-3";
  twoSelect:boolean=true;
  nineSelect:boolean=false;
  count:any = 0;
  four:any = 4;
  fourImage:any = [];
  nine:any = 9;
  themeNames:any = [];
  params:any= {};
  getFilterDetails: any;
  serviceType:any;
  queryString: string;

  constructor(
    private dataService: DataService,
    private router: Router,
    private _location: Location,
    private route:ActivatedRoute,
  )
  {}
  ngOnInit()
  {
    this.route.queryParams
      .subscribe(params => {
        this.queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
    );
    this.serviceType = localStorage.getItem("serviceType");
    this.getThemes(); 
    this.catlist = JSON.parse(localStorage.getItem("catalogueListing"));
  }
  goback() {
    this._location.back();
}
  selectedTheme(id)
  {  
    if(!localStorage.getItem("EntireHouseTheme"))
    {
      localStorage.setItem("EntireHouseTheme",id);
    }
    //localStorage.removeItem('selectedTheme');
    this.catlist = JSON.parse(localStorage.getItem("catalogueListing"));
    localStorage.setItem("selectedTheme",id);
    for(var i=0;i<this.catlist.length;i++)
    {
      if(this.catlist[i].t_mst_sub_cat_id != id)
      {
        this.catlist.splice(i,1);
        i--;
      }
    }
    localStorage.setItem("subcatalogueListing",JSON.stringify(this.catlist));
    this.router.navigateByUrl(`/cataloguelisting?${this.queryString}`);
  }

  getThemes() {
     this.id=parseInt(localStorage.getItem("selectedEntireCat"));
      this.dataService.getThemes(`${this.id}?${this.queryString}`).subscribe(res =>
    {
      this.Themes = res;
      for(let i=0;i<this.Themes.length;i++){
        this.themeNames.push(this.Themes[i].name)
      }
      // alert(this.themeNames)
     
      console.log(this.Themes);
      console.log(this.Themes.name)
      // alert(this.Themes.name)
    });
  } 
  description(name)
    {   this.desc="";
        this.dataService.getDescription(name).subscribe(
        data123 => {
          this.desc = data123['message'];
        });
        let desc = document.getElementsByClassName('theme-description') as HTMLCollectionOf<HTMLElement>;
        let desc2 = document.getElementsByClassName('theme-disp-desc') as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < desc.length; i++) {
          let element = desc[i];
          let element2 = desc2[i];
          if(element.style.visibility != 'visible')
          {
            element.style.visibility = 'visible';
            element2.style.visibility = 'hidden';
          }
          else{
            element.style.visibility = 'hidden';
            element2.style.visibility = 'visible';
          }
          
        }
        
        setTimeout(function() {
          for (let i = 0; i < desc.length; i++) {
            let element = desc[i];
            let element2 = desc2[i];
            if(element.style.visibility == 'visible')
            {
              element.style.visibility = 'hidden';
              element2.style.visibility = 'visible';
            }
          }
        }, 4000);

        // let desc1 = document.getElementsByClassName('theme-description') as HTMLCollectionOf<HTMLElement>;
        // for (let i = 0; i < desc1.length; i++) {
        //   let element = desc1[i];
        //   element.style.visibility = 'hidden';
        // }
      /*Swal.fire({
      position: 'bottom-start',
      title: this.desc,
      showConfirmButton: false,
      timer: 5000
    });*/
  }
  changed_function(){
    let desc = document.getElementsByClassName('theme-description') as HTMLCollectionOf<HTMLElement>;
    let desc2 = document.getElementsByClassName('theme-disp-desc') as HTMLCollectionOf<HTMLElement>;
    
        for (let i = 0; i < desc.length; i++) {
          let element = desc[i];
          element.style.visibility = 'hidden';
          let element2 = desc2[i];
          if(element2)
          element2.style.visibility = 'visible';
        }
  }

  changeToFour(){
    this.nineSelect = false;
    this.twoSelect = true;
    this.themeStyle = "col-lg-3";
    // alert(this.twoSelect);
    this.count=0;
  }
  changeToNine(){
    this.twoSelect = false;
    this.nineSelect = true;
    this.themeStyle = "col-lg-2";
    // alert(this.nineSelect);
    this.count=0;
    // this.fourImage.pop();
    // this.fourImage.pop();
    
  }
  changeToOne(){
    this.twoSelect = false;
    this.nineSelect = false;
    this.themeStyle = "col-lg-12";
    // alert(this.twoSelect);
  }

  log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }

}