import { Component } from '@angular/core';
// import { stepper } from './route-animation';
import { RouterOutlet,ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { trigger, transition, useAnimation } from "@angular/animations";
import {
	AnimationType,
	scaleIn,
	scaleOut,
	fadeIn,
	fadeOut,
	flipIn,
	flipOut,
	jackIn,
	jackOut
  } from "./pages/categories/categories.animation";
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger("slideAnimation", [
      /* scale */

      
      transition("* => scale", [
        useAnimation(scaleIn, { params: { time: "500ms" } })
      ]),
      transition("scale => *", [
        useAnimation(scaleOut, { params: { time: "500ms" } })
      ]),

      /* fade */
      transition("void => fade", [
        useAnimation(fadeIn, { params: { time: "500ms" } })
      ]),
      transition("fade => void", [
        useAnimation(fadeOut, { params: { time: "500ms" } })
      ]),

      /* flip */
      transition("void => flip", [
        useAnimation(flipIn, { params: { time: "500ms" } })
      ]),
      transition("flip => void", [
        useAnimation(flipOut, { params: { time: "500ms" } })
      ]),

      /* JackInTheBox */
      transition("void => jackInTheBox", [
        useAnimation(jackIn, { params: { time: "700ms" } })
      ]),
      transition("jackInTheBox => void", [
        useAnimation(jackOut, { params: { time: "700ms" } })
      ])
    ])
  ],

})

export class AppComponent {

  title = 'persftproject';

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


  constructor(private bnIdle: BnNgIdleService,private router: Router) {
    this.bnIdle.startWatching(1000).subscribe((res) => {
      if(res) {
        this.router.navigateByUrl('/logout');
      }
    })     
  }

  ngOnInit()
  {
 
  }
}
