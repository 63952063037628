import { Component, OnInit, ViewEncapsulation, Injectable, Input  } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from '../data.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'], 

  encapsulation: ViewEncapsulation.None
})

export class CategoriesComponent implements OnInit
{   
    @Input('compare') compare: Compare;
    @Input('wishlist') wishlist: Wishlist;
    @Input() cart:Cart;
     cust_id: number;
     logout:any;
     public getcount: any;
	 Categories: any;
	 id: any;
     categoriescustomOptions: OwlOptions= {
        loop:true,
        autoplay:false,
        center: true,
        items:2,
        margin:0,
        nav:true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplayTimeout:40000,
        smartSpeed:1000,
        autoplayHoverPause: true,
        dots: false,
        navText: ['<i class="fa fa-angle-double-left"></i>',
        '<i class="fa fa-angle-double-right"></i>'],
        responsive: {
            0: {
                items: 2
            }
            ,
            640: {
                items: 2
            }
            ,
            768: {
                items: 2
            }
            ,
            991: {
                items: 2
            }
            ,
            1024: {
                items: 2
            }
            ,
            1280: {
                items: 2
            }
        }
    }
    constructor(
    	private dataService:DataService, 
    	private route:ActivatedRoute, 
    	private _location: Location
    ) {}
    
    goback() {
        this._location.back();
    }

    ngOnInit() {
        localStorage.setItem("serviceType",'2');
        this.id=this.route.snapshot.params.id;
        this.getCategoryData();
    }

    getCategoryData() {
        this.dataService.getData().subscribe(res=> {
            this.Categories=res;
        }
        );
    }
}
