import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})
export class ExcelServiceService {

	constructor(	) { }

	public exportAsExcelFile(json:any[], excelFileName: string): void {

    let offshellArray:any[]=json['offshelf'];
    // let entireArray:any[]=json['entire'];
    let singleArray:any[]=json['single'];
    var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(offshellArray);
    // var worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(entireArray);
    var worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(singleArray);
    
    // var workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "People");
    const workbook: XLSX.WorkBook = 
    { 
      Sheets: 
      { 'Offshelf Products': worksheet,
        // 'Entire House Products': worksheet1,
        'Single Room Products': worksheet2
      }, SheetNames: 
      [
        'Offshelf Products',
        // 'Entire House Products',
        'Single Room Products'
      ] 
    };
 
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		this.saveAsExcelFile(excelBuffer, excelFileName);
	}
	
	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName  + '__'+ new  Date().getFullYear() + '__'+  new  Date().getMonth() + '__'+  new  Date().getDate()  + '_export_'+ EXCEL_EXTENSION);

    
  }
 

}
