import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-finishpage',
  templateUrl: './finishpage.component.html',
  styleUrls: ['./finishpage.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FinishpageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
