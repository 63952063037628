import { Component, OnInit } from '@angular/core';
import config from '../../config/config';

@Component({
  selector: 'app-welcomepage',
  templateUrl: './welcomepage.component.html',
  styleUrls: ['./welcomepage.component.css']
})
export class WelcomepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goProd()
	{
		window.location.href=`${config.BASE_URL}branchlogin`;
	}

}
