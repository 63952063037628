const config = {
	// API_ENDPOINT : 'https://uat.persft.com/webservice/api',
	// API_ENDPOINT : 'http://13.234.202.63/design/webservice/api',
	// API_ENDPOINT : 'http://13.234.202.63/designnew/webservice/api',
	// API_ENDPOINT : 'https://uat.persft.com/webservice/api', // This was being used by persft
	// API_ENDPOINT : 'http://13.126.160.234/persft/webservice/api',
	// API_ENDPOINT : 'http://localhost:8000/api',
	API_ENDPOINT : 'https://uat.persft.com/webservice/api',
	// API_ENDPOINT : 'https://uat.persft.com/webservice/api',
	BASE_URL : 'https://uat.persft.com/',
    //IMAGE_SHAPE : [{value: '1',label:'Circle'},{value: '2',label:'Rectangle'},{value: '3',label:'Square'}],
    IMAGE_SHAPE : [{value: '2',label:'Rectangle'},{value: '3',label:'Square'}],
    IMAGE_THEMES : [
	    {value: '1',label:'Theme 1',image:'assets/images/categories/slide-4-1.jpg'},
	    {value: '2',label:'Theme 2',image:'assets/images/categories/pic22.jpg'},
	    {value: '3',label:'Theme 3',image:'assets/images/categories/pic24.jpg'}
    ],
    CART_COUNT: 0
}
export default config;