import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root'
})

export class AfterLoginService {

	constructor(
		private router: Router,
		private tokenService: TokenService
	) {}

	canActivate(route: ActivatedRouteSnapshot, 
		state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		// console.log(this.tokenService.loggedIn());
		// console.log(this.tokenService.isTokenExpired());
		if (this.tokenService.loggedIn() && !this.tokenService.isTokenExpired()) {
			return true;
		}
		this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
		return false;
	}
}