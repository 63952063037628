import { style } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, ElementRef } from '@angular/core';
import { SlideInOutAnimation } from '../../route-animation';
import { DataService } from '../data.service';
import { Options } from 'ng5-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Compare } from '../comparecount/comparecount.model';
//import 'material-tooltip';



@Component({
  selector: 'app-catalogudesigning',
  templateUrl: './catalogudesigning.component.html',
  styleUrls: ['./catalogudesigning.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [SlideInOutAnimation]
})

export class CatalogudesigningComponent implements OnInit {
  @Input() cart: Cart;
	@Input('compare') compare: Compare;
	@Output() sidenavClose = new EventEmitter();
	layid: any;
	id: any;
	row: any;
	col: any;
	statusClass: any;
	styling: any = 0;
	styling1: any = 1;
	customOptions: OwlOptions = {
		nav: true,

		navText: [
			'<i class="fa fa-long-arrow-left" aria-hidden="true" ></i>',
			'<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'
		],
		loop: false,
		autoplayTimeout: 10000,
		smartSpeed: 2000,
		autoplay: false,
		margin: 0,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,

		dots: false,
		responsive: {
			0: {
				items: 1
			},
			640: {
				items: 1
			},
			768: {
				items: 1
			},
			991: {
				items: 1
			},

			1024: {
				items: 4
			},

			1280: {
				items: 5
			}

		}

	}
	base_layid: number;
	cataloguevalues: any;
	productsdata: any;
	params: any = {};
	loadParams: any = {};
	product_row: any;
	product_col: any;
	filterParams: any = {};
	selectedproducts: any;
	transitions: any;
	viewproducts: any;
	elementslist: any;
	productslist: any;
	package: any;
	used_design_count: any;
	wishlist_count: any;
	viewmoreinfo: any = {};
	snackBar: any;
	status: boolean = false;
	status2: boolean = false;
	classApplied = false;
	drawer: any;
	closeResult: string;
	// public show:boolean = false;
	// public prevClicked:number = -1;
	minValue: number = 10;
	maxValue: number = 245400;
	demoParams: any = {};
	imgpath: string;
	demolayouts: any;
	options: Options = {
		floor: 0,
		ceil: 250000
	};
	layoutdetails: any = {};
	public _opened: boolean = false;
	animationState = 'in';
	eleid: any;
	totalMinAmount: number;
	public totalMaxAmount: number;
	t_rel_limg_id: number;
	theme: number;
	hotspotEleid: number;
	hotspotPrdid: number;
	hotspotRow: number;
	hotspotCol: number;
	activeProduct: number;
	layoutlength: any;
	layoutbreadth: any;
	layoutheight: any;
	selectedCatInd: string;
	videourl: any;
	iframeMarkup: string;
	videoId: any;
	convertlink: string;
	cust_id: number;
	cartcount: any;
	wishlist_uniqueid: any;
	selectedHotspotProduct: number;
	changedHotspot: string;
	transitionalimages: any;
	cataloguevalue: any;
	ImagesLength: any;
	countt: any = 1;
	nextImage: any = [];
	styleid: any;
	selectedIndex: any;
	flag: number;
	hotspotDefault: any = [0];
	hotspotDefaultrow: any = [0];
	choose: any;
	selectHot: any;
	colorHot: any;
	defaultBorder: any = 0;
  public catelogSlide = false;

	constructor(
		public modalService: NgbModal,
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router, private _location: Location,
	) {

		this.layid = this.route.snapshot.params.id;
	}
	clickEvent() {
		this.status = !this.status;
	}
	updateStatus2() {
		this.status2 = !this.status2;
	}
	goback() {
		this._location.back();
	}

	ngOnInit() {

		this.gethotspots();
		this.getallselectedproducts();
		this.getTransitions();
		this.demoParams['id'] = this.layid;
		this.demoParams['serviceType'] = localStorage.getItem("serviceType");
		this.dataService.getImgInfo(this.demoParams).subscribe(res => {
			this.imgpath = res['imgpath'];
			this.demolayouts = res['layouts'];
			// console.log(this.demolayouts);
			this.styleid = res['styleid'];
			// console.log(this.styleid);
		});
		this.cust_id = parseInt(localStorage.getItem("cust_id"));
		// console.log(this.cust_id);
		this.dataService.getPackageCount(this.cust_id).subscribe(res => {
			// console.log("count"+this.package);
			this.package = res;
			console.log("package" + this.package);
		});

		this.dataService.getUsedDesignCount(this.cust_id).subscribe(res => {
			// console.log("count"+this.package);
			this.used_design_count = res;
			console.log("used_design_count" + this.used_design_count);
		});

		this.dataService.getWishlistcountProducts(this.cust_id).subscribe(res => {
			this.wishlist_count = Object.keys(res).length;
			// console.log(this.wishlist_count);
		});
	}

	getallselectedproducts() {
		this.loadParams['id'] = this.layid;
		this.loadParams['serviceType'] = localStorage.getItem("serviceType");
		this.dataService.getSelectedProducts(this.loadParams).subscribe(res => {
			this.selectedproducts = res;
			for (let i = 0; i < this.selectedproducts.length; i++) {
        this.choose = this.selectedproducts[i].transitions;
        // console.log("====="+this.selectedproducts[i].transitions);
				if (this.choose === 1) {
        //   console.log(this.selectedproducts[i].col_no - 1);
        //   console.log(this.selectedproducts[i].row_no - 1);
					this.hotspotDefault.push(this.selectedproducts[i].col_no - 1);
					this.hotspotDefaultrow.push(this.selectedproducts[i].row_no - 1);
				}
				this.choose = false;
			}
			var getLayoutId = 0;
			// console.log(this.selectedproducts);

			this.selectedproducts.forEach(function(value, key) {
				getLayoutId = value.t_mst_layout_id;
				// if(value.base_price==0){
				//   console.log(key);
				//   console.log(value.base_price);
				//   this.selectedproducts.splice(key,1);
				// }
			});
			// this.selectedproducts.forEach(function (value)
			// {
			//   getLayoutId=value.t_mst_layout_id;
			//   this.selectedproducts.splice(indexValue,1);
			// });
			this.base_layid = getLayoutId;
			// console.log("getLayoutId:" + getLayoutId);
			// console.log("main:" + this.base_layid);
			this.calculateAmount();
		});
	}

	battleInit() {
		throw new Error('Method not implemented.');
	}

	getTransitions() {
		this.loadParams['id'] = this.layid;
		this.loadParams['serviceType'] = localStorage.getItem("serviceType");
		this.dataService.getTransitions(this.loadParams).subscribe(res => {
			this.transitions = res;
			this.transitions = this.transitions.split("|");
			// console.log(this.transitions);
		});
	}

	gethotspots() {
		this.loadParams['id'] = this.layid;
		this.loadParams['serviceType'] = localStorage.getItem("serviceType");
		this.dataService.getCatalogueDesign(this.loadParams).subscribe(
			data => {
				this.cataloguevalues = data;
				// this.cataloguevalue = this.cataloguevalues[0];
				// console.log(this.cataloguevalues);
				var getid = 0;
				var gettheme = 0;
				var getlength = 0;
				var getbreadth = 0;
				var getheight = 0;
				this.cataloguevalues.forEach(function(value) {
					getid = value.t_rel_limg_id;
					gettheme = value.theme;
					getlength = value.length;
					getbreadth = value.breadth;
					getheight = value.height;
				});
				this.t_rel_limg_id = getid;
				this.theme = gettheme;
				this.layoutlength = getlength;
				this.layoutbreadth = getbreadth;
				this.layoutheight = getheight;
				// console.log(this.cataloguevalues);
				// console.log(this.t_rel_limg_id);
			}
		);
	}
	updatecart() {
		this.cart = new Cart();
		this.cust_id = parseInt(localStorage.getItem("cust_id"));
		this.dataService.getcountProducts(this.cust_id).subscribe(res => {
			this.cart.cartcount = res;
			this.cartcount = this.cart.cartcount
		});
	}

	compareproducts() {
		var x = JSON.parse(localStorage.getItem("compareproductid"));
		var elemid = this.viewmoreinfo.element_id;
		var prodid = this.viewmoreinfo._id;
		var check = 0;
		var sameprod = 0;
		if (localStorage.getItem("replaceId") != null) {
			var y = JSON.parse(localStorage.getItem('compareelementid'));
			if (y != elemid) {
				Swal.fire({
					title: 'A product of a different element is present.',
					text: 'Do You Want To Replace Different element?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, replace it!',
					cancelButtonText: 'No, keep it',
					confirmButtonColor: 'red',
					cancelButtonColor: 'green'
				}).then((check) => {
					if (check.value) {
						localStorage.removeItem("replaceId");
						localStorage.removeItem("compareproductid");
						localStorage.removeItem("compareelementid");
						var pid = [prodid];
						localStorage.setItem("compareproductid", JSON.stringify(pid));
						var elementid = [elemid];
						localStorage.setItem("compareelementid", JSON.stringify(elementid));
						Swal.fire({
							title: 'Yes Compared!',
							text: 'Your Previous Product has been deleted.',
							icon: 'success',
							confirmButtonText: 'Okay',
							confirmButtonColor: 'Green'
						}).then(function() {

							window.location.href = "/compareproducts";
						})
						this.updatecompare();
					} else if (check.dismiss === Swal.DismissReason.cancel) {
						Swal.fire(
							'Cancelled',
							'Your imaginary file is safe :)',
							'error'
						)
					}
				})
			} else {
				var x = JSON.parse(localStorage.getItem('compareproductid'));
				var replaceindex = localStorage.getItem("replaceId");
				var checkpidexists = x.findIndex(id => id == prodid);
				if (checkpidexists == -1) {
					x.splice(replaceindex, 1, prodid);
					localStorage.setItem("compareproductid", JSON.stringify(x));
					//this.alertService.success('Welcome', 'Welcome back.');
					localStorage.removeItem("replaceId");
					Swal.fire({
						title: 'Product Is Added In Compared List',
						icon: 'success',
						cancelButtonText: 'No, keep it',
						cancelButtonColor: 'green'
					});

				} else {

					Swal.fire('This product is already in compare ');


				}


			}




		} else {
			if (localStorage.getItem("compareproductid") === null && localStorage.getItem("compareelementid") === null) {
				var pid = [prodid];
				localStorage.setItem("compareproductid", JSON.stringify(pid));
				var elementid = [elemid];
				localStorage.setItem("compareelementid", JSON.stringify(elementid));

				Swal.fire({
					title: 'Product Is Added In Compared List',
					icon: 'success',
					cancelButtonText: 'No, keep it',
					cancelButtonColor: 'green'
				});
				this.updatecompare();
			} else {
				var redirectcart = 0;
				var myObj_deserialized1 = JSON.parse(localStorage.getItem("compareelementid"));
				myObj_deserialized1.forEach(function(value) {
					if (value == elemid) {
						var comparepid2 = JSON.parse(localStorage.getItem("compareproductid"));
						if (comparepid2.length < 3) {
							comparepid2.forEach(function(value1) {
								if (value1 == prodid) {

									check = 0;
									sameprod = 1;
								} else {
									check = 1;

								}
							});
						} else {
							Swal.fire('You cant compare more than 3 products at the same time.');
						}
					} else {
						Swal.fire({
							title: 'A product of a different element is present.',
							text: 'You will not be able to recover this imaginary file!',
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: 'Yes,Remove it!',
							cancelButtonText: 'No, Add it',
							confirmButtonColor: 'red',
							cancelButtonColor: 'green'
						}).then((check) => {
							if (check.value) {

								localStorage.removeItem("compareproductid");
								localStorage.removeItem("compareelementid");
								var pid = [prodid];
								localStorage.setItem("compareproductid", JSON.stringify(pid));
								var elementid = [elemid];
								localStorage.setItem("compareelementid", JSON.stringify(elementid));
								Swal.fire({
									title: 'Yes Compared!',
									text: 'Your Previous Product has been deleted.',
									icon: 'success',
									confirmButtonText: 'Okay',
									confirmButtonColor: 'Green'
								}).then(function() {
									//  redirectcart=1

									window.location.href = "/compareproducts";
								})

								// sameprod = 2;
								// alert("inside="+sameprod);
								// console.log("hello");
							} else if (check.dismiss === Swal.DismissReason.cancel) {
								Swal.fire(
									'Cancelled',
									'Your Previous Product  is there :)',
									'error'
								)
							}
						})
					}
					// alert(sameprod);
					if (sameprod == 1) {
						Swal.fire({
							title: 'same product is there',
							icon: 'error',

						});
					}
					// else if(sameprod == 2){
					//   this.updatecompare();
					// }
					else if (check == 1) {
						var comparepid3 = JSON.parse(localStorage.getItem("compareproductid"));
						comparepid3.push(prodid);
						localStorage.setItem("compareproductid", JSON.stringify(comparepid3));

						Swal.fire({
							title: 'Product Is Added In Compared List',
							icon: 'success',
							cancelButtonText: 'No, keep it',
							cancelButtonColor: 'green'
						});
						check = 0;
						redirectcart = 1

					}
				});
				if (redirectcart == 1) {
					this.updatecompare();
				}
			}
		}
	}
	updatecompare() {
		throw new Error("Method not implemented.");
	}
	getproductfilters(eleid, prdid, row, col) {
    this.catelogSlide = true
		this.defaultBorder = 0;
		this.selectedIndex = null;
		this.viewmoreinfo = [];
		if (this._opened == true) {
			this._opened = false;
		}
		this.status2 = true;
		localStorage.removeItem('filterEleId');
		localStorage.removeItem('filterVarId');
		localStorage.removeItem('filterVarVal');
		this.product_row = row;
		this.product_col = col;
		// console.log("here:" + this.base_layid);
		this.params['id'] = this.base_layid;

		this.params['eleid'] = eleid;
		this.params['prdid'] = prdid;
		this.params['row'] = row;
		this.params['col'] = col;
		var serviceType = JSON.parse(localStorage.getItem("serviceType"));
		if (serviceType == 2) {
			this.params['subcatid'] = JSON.parse(localStorage.getItem("selectedSubCat"));
		} else if (serviceType == 1) {
			this.params['subcatid'] = JSON.parse(localStorage.getItem("selectedTheme"));
		}

		this.product_row = row;
		this.product_col = col;
		this.params['styleid'] = this.styleid;
		this.dataService.getProductsFilters(this.params).subscribe(
			data => {
				// console.log(this.params);
				this.productsdata = data;
				this.elementslist = this.productsdata.elements;
				this.productslist = this.productsdata.products;
				this.ImagesLength = this.productslist.length;
				this.nextImage.push(Math.ceil(this.ImagesLength / 4));

				// console.log(this.ImagesLength);
				if (this.productsdata.least == this.productsdata.max) {
					this.minValue = this.productsdata.least - 1;
					this.maxValue = this.productsdata.max;
					this.options = {
						floor: this.productsdata.least - 1,
						ceil: this.productsdata.max
					};
				} else {
					this.minValue = this.productsdata.least;
					this.maxValue = this.productsdata.max;
					this.options = {
						floor: this.productsdata.least,
						ceil: this.productsdata.max
					};
				}
				this.eleid = eleid;
				var active;
				this.selectedproducts.forEach(selected => {
					this.productslist.forEach(allproducts => {
						if (allproducts.t_mst_product_id == selected.t_mst_product_id && row == selected.row_no && col == selected.col_no && selected.base_price != 0) {
							active = selected.t_mst_product_id;
						}
					})
				});
				this.activeProduct = active;
				this.selectedHotspotProduct = active;
				// console.log("getproductfilters:-active:"+this.activeProduct+"selected:"+this.selectedHotspotProduct);

				this.getMoreInfoPopup(this.activeProduct);
			}
		);
		this.hotspotEleid = eleid;
		this.hotspotPrdid = prdid;
		this.hotspotRow = row;
		this.hotspotCol = col;
	}

	submitToCart() {
		if (localStorage.getItem("serviceType") == '1') {
			this.layoutdetails.t_mst_cust_id = localStorage.getItem("cust_id");
			this.layoutdetails.t_mst_layout_id = this.base_layid;
			this.layoutdetails.t_rel_limg_id = this.layid;
			this.layoutdetails.theme = this.theme;
		} else {
			this.layoutdetails.t_mst_cust_id = localStorage.getItem("cust_id");
			this.layoutdetails.t_mst_layout_id = this.layid;
			this.layoutdetails.t_rel_limg_id = this.t_rel_limg_id;
			this.layoutdetails.theme = this.theme;
		}
		this.dataService.addToCart(this.selectedproducts, this.layoutdetails).subscribe(data => {
			// console.log(data);
			if (localStorage.getItem("serviceType") == '1') {
				var status = JSON.parse(localStorage.getItem("entireHouseStatus"));
				var price = this.totalMaxAmount;
				var i = 1;
				var check = 0;
				var y = [];
				var outercount = 0;
				var innercount = 0;
				var selectedCat = localStorage.getItem("selectedEntireCat");
				var newImage = this.cataloguevalues[0].img;
				// var selectedCatIndx=localStorage.getItem("selectedEntireCatIndex");
				status.forEach(function(value) {
					// console.log(data);
					outercount++;
					if (value == 'Y') {
						y.push(value);
					} else if (i == parseInt(localStorage.getItem("selectedEntireCatIndex")) && value == 'N' && check != 1) {
						y.push('Y');
						var amounts = JSON.parse(localStorage.getItem("entireHouseAmounts"));
						var carts = JSON.parse(localStorage.getItem("entireHouseCart"));
						var images = JSON.parse(localStorage.getItem("entireHouseImages"));

						amounts.splice((outercount - 1), 1, price); //splice at newindex
						carts.splice((outercount - 1), 1, data); //splice at newindex
						images.splice((outercount - 1), 1, newImage);
						localStorage.setItem("entireHouseAmounts", JSON.stringify(amounts));
						localStorage.setItem("entireHouseCart", JSON.stringify(carts));
						localStorage.setItem("entireHouseImages", JSON.stringify(images));
						check = 1;
					} else {
						y.push('N');
						// console.log(y);
					}
					i++;
				});

				if (check != 0) {
					localStorage.setItem("entireHouseStatus", JSON.stringify(y));
				}
				var count = 0;
				var status = JSON.parse(localStorage.getItem("entireHouseStatus"));
				status.forEach(function(value) {
					if (value == 'N') {
						count++;
					}
				});
				if (count == 0) {
					Swal.fire({
						title: 'Added to cart',
						icon: 'success',
						showCancelButton: false,
						confirmButtonText: 'OK',
						confirmButtonColor: 'primary',
					}).then((check) => {
						if (check.value) {
							this.router.navigateByUrl('/cart');
						}
					})
				} else {
					Swal.fire({
						title: 'Added to cart',
						icon: 'success',
						showCancelButton: false,
						confirmButtonText: 'OK',
						confirmButtonColor: 'primary',
					}).then((check) => {
						if (check.value) {
							this.router.navigateByUrl('/roomsforentirehouse');
						}
					})
				}
			} else {
				Swal.fire({
					title: 'Added to cart',
					icon: 'success',
					showCancelButton: false,
					confirmButtonText: 'OK',
					confirmButtonColor: 'primary',
				}).then((check) => {
					if (check.value) {
						this.router.navigateByUrl('/cart');
					}
				})
			}

		});
	}

	doubleClick(content, id) {
		this.openVerticallyCentered(content);
		this.viewmore(id);
	}

	multifunction(content, id) {
		this.openVerticallyCentered(content);
		this.viewmore(id);
	}

	viewmore(id) {
		this.dataService.getMoreInfo(id).subscribe(res => {
			this.viewmoreinfo = res;
			// console.log(this.viewmoreinfo);
			// this.activeProduct=id;
			// this.selectedHotspotProduct=id;
			this.videourl = this.viewmoreinfo.url
			if (this.videourl != null) {
				function getId(videourl) {
					const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
					const match = videourl.match(regExp);
					return (match && match[2].length === 11) ?
						match[2] :
						null;
				}
				this.videoId = getId(this.viewmoreinfo.url);
				this.iframeMarkup = 'https://www.youtube.com/embed/' +
					this.videoId;
				this.convertlink = this.iframeMarkup
			}
		});
	}

	getMoreInfoPopup(id) {

		this.dataService.getMoreInfoPopup(id).subscribe(res => {
			this.viewmoreinfo = res;
			for (let i = 0; i < this.productslist.length; i++) {
				if (this.productslist[i].t_mst_product_id == this.viewmoreinfo.t_mst_product_id) {
					var temp;
					temp = this.viewmoreinfo.base_price;
					this.viewmoreinfo.calc_price = temp;
					this.viewmoreinfo.base_price = this.productslist[i].base_price;
				}
			}
			// alert(this.viewmoreinfo);
			// console.log(this.viewmoreinfo);
			this.activeProduct = id;
			this.selectedHotspotProduct = id;
			this.videourl = this.viewmoreinfo.url
			if (this.videourl != null) {
				function getId(videourl) {
					const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
					const match = videourl.match(regExp);
					return (match && match[2].length === 11) ?
						match[2] :
						null;
				}
				this.videoId = getId(this.viewmoreinfo.url);
				this.iframeMarkup = 'https://www.youtube.com/embed/' +
					this.videoId;
				this.convertlink = this.iframeMarkup
			}
		});
	}


	onFilterChange(eve: any, varVal, varId, eleid) {
		localStorage.setItem("filterEleId", eleid);
		if (eve.target.checked == true) {
			if (localStorage.getItem("filterVarId") === null && localStorage.getItem("filterVarVal") === null) {
				localStorage.setItem("filterVarId", JSON.stringify([varId]));
				localStorage.setItem("filterVarVal", JSON.stringify([{
					varVal: [varVal]
				}]));
			} else {
				var myObj_deserialized1 = JSON.parse(localStorage.getItem("filterVarId"));
				var myObj_deserialized2 = JSON.parse(localStorage.getItem("filterVarVal"));
				if (myObj_deserialized1.indexOf(varId) != -1) //same variant
				{
					var indexValue = myObj_deserialized1.indexOf(varId);
					var i = 0;
					myObj_deserialized2.forEach(function(value) {
						if (i == indexValue) {
							if (value.varVal.indexOf(varVal) == -1) {
								value.varVal.push(varVal);
							}
						}
						i++;
					});
					localStorage.setItem("filterVarVal", JSON.stringify(myObj_deserialized2));
				} else //different variant
				{
					myObj_deserialized1.push(varId);
					localStorage.setItem("filterVarId", JSON.stringify(myObj_deserialized1));
					myObj_deserialized2.push({
						varVal: [varVal]
					});
					localStorage.setItem("filterVarVal", JSON.stringify(myObj_deserialized2));
				}
			}
		} else { //remove from local storage if unchecked
			var myObj_deserialized1 = JSON.parse(localStorage.getItem("filterVarId"));
			var myObj_deserialized2 = JSON.parse(localStorage.getItem("filterVarVal"));
			var indexValue = myObj_deserialized1.indexOf(varId);
			var i = 0;
			myObj_deserialized2.forEach(function(value) {
				if (i == indexValue) {
					value.varVal.splice(value.varVal.indexOf(varVal), 1);
					if (value.varVal.length == 0) {
						myObj_deserialized1.splice(indexValue, 1);
						myObj_deserialized2.splice(indexValue, 1);
					}
				}
				i++;
			});
			localStorage.setItem("filterVarId", JSON.stringify(myObj_deserialized1));
			localStorage.setItem("filterVarVal", JSON.stringify(myObj_deserialized2));
			// localStorage.setValue("", JSON.stringify(''));
		}
	}

	filterClick() {
		this.toggleSidebar();
		this.filter();
		// localStorage.setValue('');
	}

	filter() {
		if (localStorage.getItem("filterVarId") != null || localStorage.getItem("filterVarVal") != null || localStorage.getItem("filterVarVal") != null || this.minValue != 0 || this.maxValue != 0) // this.minValue != this.productsdata.least || this.maxValue != this.productsdata.max
		{
			this.filterParams['varId'] = [];
			this.filterParams['varVal'] = [];
			this.filterParams['eleId'] = [];

			if (localStorage.getItem("filterVarId") != null) {
				this.filterParams['varId'] = JSON.parse(localStorage.getItem('filterVarId'));
			}

			if (localStorage.getItem("filterVarVal") != null) {
				this.filterParams['varVal'] = JSON.parse(localStorage.getItem('filterVarVal'));
			}

			if (localStorage.getItem("filterVarVal") != null) {
				this.filterParams['eleId'] = JSON.parse(localStorage.getItem('filterEleId'));
			} else {
				this.filterParams['eleId'] = this.eleid;
			}

			this.filterParams['minBug'] = this.minValue;
			this.filterParams['maxBug'] = this.maxValue;
			this.filterParams['page'] = 1;
			this.filterParams['subcatid'] = JSON.parse(localStorage.getItem("selectedSubCat"));
			this.filterParams['styleid'] = this.styleid;
			// console.log(this.filterParams);
			this.dataService.getFilteredData(this.filterParams).subscribe(
				data => {
					this.productslist = data;
				}
			);
		}
		// localStorage.removeItem('filterEleId');localStorage.removeItem('filterVarId');localStorage.removeItem('filterVarVal');
	}


	onaddtoselectedproducts(product, row, col, pid) {
		// console.log(product);
		var serviceType = localStorage.getItem("serviceType");
		this.flag = 0;
		var img = "";
		var flag1 = 0;
		for (let i = 0; i < this.transitions.length; i++) {
			var wotrans = this.transitions[i];
			var subs = row.toString() + ":" + col.toString() + ":" + this.viewmoreinfo.t_mst_elm_id.toString() + ":";
			if (wotrans != null && subs != null) {
				if (wotrans.includes(subs) == true) {
					flag1 = 1;
					this.transitions[i] = row.toString() + ":" + col.toString() + ":" + this.viewmoreinfo.t_mst_elm_id.toString() + ":" + pid.toString();
				}
			}
		}
		// console.log(this.transitions);

		this.dataService.getTransitionalHotspotsImages(this.base_layid).subscribe(
			data => {
				this.transitionalimages = data;
				// console.log(this.transitionalimages);
				this.transitionalimages.forEach(value => {
					const string = value.hotspots;
					img = value.layout_image;
					var ctr = 0;
					for (let i = 0; i < this.transitions.length; i++) {
						var wotrans = this.transitions[i];
						if (string != null && wotrans != null) {
							if (string.includes(wotrans) == true && flag1 == 1) {
								ctr = ctr + 1;
							}
						}
					}
					if (ctr === this.transitions.length) {
						this.flag = 1;
						// console.log(this.flag);
						this.cataloguevalues.forEach(ele => {
							ele.image = img;
						});
					}
				});
				this.activeProduct = pid;
				this.selectedHotspotProduct = pid;
				// console.log("onaddtoselectedproducts:-active:"+this.activeProduct+"selected:"+this.selectedHotspotProduct);
				var indexValue = this.selectedproducts.findIndex(selectproduct => selectproduct.t_mst_product_id === product.t_mst_product_id);
				product.row_no = row;
				product.col_no = col;
				// console.log(this.flag);
				if (this.flag == 0) {
					if (indexValue == -1) {
						if (this.selectedproducts.some(selectproduct => selectproduct.row_no == product.row_no) && this.selectedproducts.some(selectproduct => selectproduct.col_no == product.col_no)) {
							var newindex = this.selectedproducts.findIndex(selectproduct => (selectproduct.col_no == product.col_no && selectproduct.row_no == product.row_no));
							this.selectedproducts.splice(newindex, 1, product); //splice at newindex
							// Swal.fire('');
							Swal.fire({
								html: '<p>Selected product/service changes will be reflected after finalizing the entire room design, please request for the same in the cart</p>',
								customClass: {
									popup: 'format-pre'
								}
							});
							// this.toggleSidebar();
						} else {
							this.selectedproducts.push(product);
							// Swal.fire('');
							Swal.fire({
								html: '<p>Selected product/service changes will be reflected after finalizing the entire room design, please request for the same in the cart</p>',
								customClass: {
									popup: 'format-pre'
								}
							});
							this._opened = false;
							// console.log("toggleSidebar:" + this._opened);
							// this.toggleSidebar();
						}
					} else {
						var newindex = this.selectedproducts.findIndex(selectproduct => (selectproduct.col_no == product.col_no && selectproduct.row_no == product.row_no));
						this.selectedproducts.splice(newindex, 1, product); //splice at newindex
						// Swal.fire('');
						Swal.fire({
							html: '<p>Selected product/service changes will be reflected after finalizing the entire room design, please request for the same in the cart</p>',
							customClass: {
								popup: 'format-pre'
							}
						});
						this._opened = false;
						// console.log("toggleSidebar:" + this._opened);
						// this.toggleSidebar();
					}
				} else {
					if (indexValue == -1) {
						if (this.selectedproducts.some(selectproduct => selectproduct.row_no == product.row_no) && this.selectedproducts.some(selectproduct => selectproduct.col_no == product.col_no)) {
							var newindex = this.selectedproducts.findIndex(selectproduct => (selectproduct.col_no == product.col_no && selectproduct.row_no == product.row_no));
							this.selectedproducts.splice(newindex, 1, product); //splice at newindex
							// Swal.fire('');
							Swal.fire({
								html: '<p style="color:black;">Desired changes have been applied, Design changed accordingly</p>',
								customClass: {
									popup: 'format-pre'
								}
							});
							// this.toggleSidebar();
						} else {
							this.selectedproducts.push(product);
							// Swal.fire('');
							Swal.fire({
								html: '<p style="color:black;">Desired changes have been applied, Design changed accordingly.</p>',
								customClass: {
									popup: 'format-pre'
								}
							});
							this._opened = false;
							// console.log("toggleSidebar:" + this._opened);
							// this.toggleSidebar();
						}
					} else {
						var newindex = this.selectedproducts.findIndex(selectproduct => (selectproduct.col_no == product.col_no && selectproduct.row_no == product.row_no));
						this.selectedproducts.splice(newindex, 1, product); //splice at newindex
						// Swal.fire('');
						Swal.fire({
							html: '<p style="color:black; font-size:10px;">Desired changes have been applied, Design changed accordingly.</p>',
							customClass: {
								popup: 'format-pre'
							}
						});
						this._opened = false;
						// console.log("toggleSidebar:" + this._opened);
						// this.toggleSidebar();
					}
				}
				this.calculateAmount();
			}
		);

		// this.layoutdetails.t_mst_cust_id = localStorage.getItem("cust_id");
		// this.layoutdetails.t_mst_layout_id = this.layid;
		// this.layoutdetails.t_rel_limg_id = this.t_rel_limg_id;
		// this.dataService.getTransitionImage(this.selectedproducts, this.layoutdetails).subscribe(
		//   data =>
		//   {
		//     this.cataloguevalues = data;
		//     console.log(this.cataloguevalues);
		//   }
		// );
	}

	calculateAmount() {
		var min = 0.0;
		var max = 0.0;
		this.selectedproducts.forEach(function(value) {
			if (value.is_mandatory == 'y') {
				min += parseFloat(value.base_price);
			}
			max += parseFloat(value.base_price);
		});
		this.totalMinAmount = min;
		this.totalMaxAmount = max;
	}


	public toggleSidebar() {
		this._opened = !this._opened;
		// console.log("toggleSidebar:" + this._opened);
	}

	doublefun(divName: string, event) {
		this.toggleShowDiv(divName);
		// this.rotate(event);
	}

	toggleShowDiv(divName: string) {


		if (divName === 'divA') {

			this.status = !this.status;
			this.animationState = this.animationState === 'out' ? 'in' : 'out';

		}
	}

	counter(n: number): any[] {
		return Array(n);
	}

	openVerticallyVideoCentered(content2) {
		this.modalService.open(content2, {
			centered: true,
			size: 'lg',
			scrollable: true,
			backdropClass: 'light-blue-backdrop',
			windowClass: 'dark-modal'
		});
	}

	onDeleteRow(i) {
		if (this.selectedproducts.length == 1) {
			Swal.fire('one product is there');
			return false;
		} else {
			this.selectedproducts.splice(i, 1);
			this.calculateAmount();
			Swal.fire('Product has been removed from your estimate');
			return true;
		}
	}

	openVerticallyCentered(content) {
		this.modalService.open(content, {
			centered: true,
			scrollable: true,
			size: 'xl',
			backdropClass: 'light-blue-backdrop',
			windowClass: 'prod-content dark-modal'
		});
	}

	addToWishlist() {
		if (localStorage.getItem("serviceType") == '1') {
			this.layoutdetails.t_mst_cust_id = localStorage.getItem("cust_id");
			this.layoutdetails.t_mst_layout_id = this.base_layid;
			this.layoutdetails.t_rel_limg_id = this.layid;
			this.layoutdetails.theme = this.theme;
		} else {
			this.layoutdetails.t_mst_cust_id = localStorage.getItem("cust_id");
			this.layoutdetails.t_mst_layout_id = this.layid;
			this.layoutdetails.t_rel_limg_id = this.t_rel_limg_id;
			this.layoutdetails.theme = this.theme;
		}

		// if(this.package > this.wishlist_count)
		if (this.package - this.used_design_count > 0 || (this.used_design_count == 0 && this.package != 0)) {
			this.dataService.addToWishlist(this.selectedproducts, this.layoutdetails).subscribe(data => {
				this.wishlist_uniqueid = data;
				Swal.fire({
					title: 'Added to wishlist',
					icon: 'success',
					showCancelButton: false,
					confirmButtonText: 'OK',
					confirmButtonColor: 'primary',
				}).then((check) => {
					if (check.value) {
						if (localStorage.getItem("serviceType") == '1') {
							this.router.navigateByUrl('/roomsforentirehouse');
						} else {
							this.router.navigateByUrl('/categories');
						}
					}
				})
			});
		} else {
			Swal.fire({
				title: 'Wish to purchase an add-on package?',
				text: "Select a package and complete the payment!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes!'
			}).then((result) => {
				if (result.isConfirmed) {
					this.router.navigateByUrl('/payment');
				}
			})
		}
	}
	// help(event){
	//   console.log(event);
	//   // alert("event.path[0].classList[1]");
	//   // alert(event.path[0].classList[0]);

	//   if(event.path[0].classList[1] === "fa-long-arrow-right"){
	//    if(this.countt+1 < this.ImagesLength){
	//     this.countt = this.countt+4;
	//       // // alert(this.countt);   }else{
	//       //   alert(this.countt);
	//       }
	//   }else if (event.path[0].classList[1] === "fa-long-arrow-left"){
	//     if(this.countt-1 > 0){
	//       this.countt = this.countt-4;
	//       // alert(this.countt);
	//     }

	//   }else if (event.path[0].classList[0] === "owl-next"){
	//     if(this.countt+1 < this.ImagesLength){
	//     this.countt = this.countt+4;
	//       // alert(this.countt);   }else{
	//       //   alert(this.countt);
	//       }
	//   }else if (event.path[0].classList[0] === "owl-Prev"){
	//     if(this.countt-1 > 0){
	//       this.countt = this.countt-4;
	//       // alert(this.countt);
	//     }
	//   }

	// }

	setActiveClass(index) {
		this.selectedIndex = index;
		this.defaultBorder = -1;
		// alert(index+'-'+this.selectedIndex);
	};


	hotspotColor(name, colored) {
		this.selectHot = name;
		this.colorHot = colored;
		// alert(name+" "+colored);
	}

	wishToPay() {
		Swal.fire({
			title: 'Wish to design further?',
			text: "Select a package and complete the payment!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Payment!'
		}).then((result) => {
			if (result.isConfirmed) {
				this.router.navigateByUrl('/payment');
			}
		})
	}
	demoNotAvailable() {
		Swal.fire({
			title: 'Please pay to start wishlisting the designs',
			text: "Select a package and complete the payment!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Payment!'
		}).then((result) => {
			if (result.isConfirmed) {
				this.router.navigateByUrl('/payment');
			}
		})

	}

}