import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import 'sweetalert2/src/sweetalert2.scss';
import { ExcelServiceService } from '../../services/excel-service.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import config from '../../config/config';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare } from '../comparecount/comparecount.model';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material';

export interface serverFieldErrors {
    email: string;
}


@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

    public serverFieldErrors: serverFieldErrors = {
        email: ''
    };
    @ViewChild('pdfTable', {
        static: false
    }) pdfTable: ElementRef;
    @ViewChild("content", {
        static: false
    }) modalContent: TemplateRef<any>;
    @Input('compare') compare: Compare;
    //   @Input() cart: Cart;
    apiUrl: string = config.API_ENDPOINT;
    logout: any;
    cartItems: any;
    wishlistImage : string;
    registermailForm: FormGroup;
    id: any;
    alldata: any;
    service: any;
    delete: any;
    cust_id: number;
    totalMaxAmount: number;
    count: number;
    excel: any = [];
    public isSubmit: boolean = false;
    public loading: boolean = false;
    public loadingservice: boolean = false;
    public modalSubmit: boolean = false;
    public form: FormGroup;
    public contactList: FormArray;
    offshellcartItems: any;
    customCollapsedHeight: string = '42px';
    customExpandedHeight: string = '42px';
    totalMaxAmountProducts: number;
    panelOpenState = false;
    otherparams: any = {};
    params: any = {};
    cartItemsLength: number;
    offshell: any;
    offshellLength: number;
    quantity = 1;
    tax: any;
    byDefault: any;
    getData: any;
    excel1: any;
    excel2: any;
    offshelf: any;
    entire: any;
    single: any;
    productImage: string;
    catName: string;
    catUid: string;
    customerdetails: any;
    public isDisabled: boolean = true;
    emailcustomer: any;
    download: string;
    entirehouse: string;
    singleRoom: string;
    mobilecustomer: any;
    cart: any;
    cartcount: any;
    adding_tot : any;
    adding : any = [];
    nf: Intl.NumberFormat;
    result_add: any;
    constructor(
        private httpClient: HttpClient, private dataService: DataService,
        public modalService: NgbModal,
        private formBuilder: FormBuilder,
        private router: Router,
        private _location: Location,
        private excelService: ExcelServiceService
    ) { }

    ngOnInit() {
   
        // this.toggled(shown)();
        // this.toggled(this.byDefault);
        // this.toggled(j);
        this.updatecart();
        this.form = this.formBuilder.group({
            unique_id: '',
            name: ['']
            // attrs: this.formBuilder.array([this.createAttrFormGroup()],[Validators.required])
        });
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.httpClient.get(`${this.apiUrl}/front/getExportList/` + this.cust_id).subscribe(data => {
            this.excel1 = data;
            this.offshelf = this.excel1.offshelf;
            this.entire = this.excel1.entire;
            this.single = this.excel1.single;
        });

        this.registermailForm = this.formBuilder.group({
            email: ['', Validators.required]
        });

        this.dataService.getProfileDetail(this.cust_id).subscribe(
            data => {
                this.customerdetails = data;
                this.emailcustomer = this.customerdetails.email
                this.mobilecustomer = this.customerdetails.mobile_no
                if (this.emailcustomer !== null) {
                    this.isDisabled = true;
                }
                this.registermailForm.patchValue({
                    email: (this.customerdetails.email)
                });
            }
        );

        this.contactList = this.form.get('contacts') as FormArray;
        this.initial();
    }

    goback() {
        this._location.back();
    }

    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        console.log("image");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL;
    }

    initial() {
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.dataService.getWishlistProducts(this.cust_id).subscribe(
            data => {
                this.alldata = data;
                this.cartItemsLength = this.alldata.cart.length;
                this.cartItems = this.alldata.cart;
                var z = [];
                for (let i = 0; i < this.alldata.cart.length; i++) {
                    z = [];
                    for (let j = 0; j < this.alldata.cart[i].cartproducts.length; j++) {
                        
                        var a = parseInt(this.alldata.cart[i].cartproducts[j].total_price);
                        z.push(a);
                        console.log(a);
                        // alert(a);

                    }
                
               this.adding.push(z.reduce(myFunc));
                console.log(this.adding);
                
                function myFunc(total, num) {
                    return total + num
                }
                // alert(this.adding);
            }
                // this.adding = new Intl.NumberFormat();
                // this.result_add = this.adding.format(this.adding);
                // alert(this.result_add);


                console.log(z);
                // alert(this.adding);
                // this.cartItems.push(this.alldata.cart);
                
                if (this.cartItemsLength > 0) {
                    this.calculatePriceAndMessagesCount();
                }
            }
        );
    }

    counter(n: number): any[] {
        return Array(n);
    }

    openVerticallyCentered(servicelife) {
        // console.log(servicelife);
        this.modalService.open(servicelife, {
            centered: true,
            scrollable: true,
            size: 'sm',
            backdropClass: 'light-blue-backdrop',
            windowClass: 'service-modal ng-x'
        });
    }

    get name() {
        return this.form.controls['name'];
    }
    get unique_id() {
        return this.form.controls['unique_id'];
    }
    get email() {
        return this.registermailForm.controls['email'];
    }
    requestServiceLive(catUid, catName) {
        this.modalSubmit = false;
        this.loadingservice = false;
        this.catName = catName;
        this.form.patchValue({
            unique_id: catUid
        });
        this.form.controls['name'].setValue('');
    }

    submitServiceLiveRequest() {

        this.modalSubmit = true;
        this.loadingservice = true;

        // if (this.form.invalid) {
        //     this.loadingservice = false;
        //     return;
        // }
        this.otherparams.custId = this.cust_id;
        this.otherparams.total = this.totalMaxAmountProducts;
        this.dataService.requestServiceLive(this.form.value, this.otherparams).subscribe(
            data => {

                this.service = data;
                this.loadingservice = false;
                this.modalSubmit = false;
                this.form.reset();
                if (this.service.message == 1) {
                    this.modalService.dismissAll();
                    this.loadingservice = false;
                    this.modalSubmit = false;
                    this.form.reset();
                    this.modalService.open(this.modalContent).result.then((result) => {

                    });
                    this.initial();

                } else {
                    Swal.fire('Please try again later.');
                }
                this.loadingservice = false;
                this.modalSubmit = false;
                this.form.reset();
            }
        );
    }

    updatecart() {
        this.cart = new Cart();
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.dataService.getcountProducts(this.cust_id).subscribe(res => {
            this.cart.cartcount = res;
            this.cartcount = this.cart.cartcount;
        });
    }

    deleteFromWishlist(unique_id, theme) {
        var indexValue = this.cartItems.findIndex(selectcategory => selectcategory.unique_id === unique_id);
        this.cartItems.splice(indexValue, 1); //splice at newindex
        this.calculatePriceAndMessagesCount();
        this.params.type = "3"; //1->offshelf product 2->single product; 3->entire catalogue
        this.params.unique_id = unique_id;
        console.log(unique_id);
        this.dataService.deleteFromWishlist(this.params).subscribe(
            data => {
                console.log(data);
                this.delete = data;
                this.updatecart();
                this.initial();
            }
        );

    }

    addToCart(unique_id) {
        var indexValue = this.cartItems.findIndex(selectcategory => selectcategory.unique_id === unique_id);
        this.cartItems.splice(indexValue, 1); //splice at newindex
        this.calculatePriceAndMessagesCount();
        this.params.type = "3"; //1->offshelf product 2->single product; 3->entire catalogue
        this.params.unique_id = unique_id;
        console.log("HI");
        this.dataService.addToCartWishlist(unique_id).subscribe(
            data => {
                console.log(data);
                this.updatecart();
                this.initial();
                this.router.navigateByUrl('/cart');
            });
    }

    calculatePriceAndMessagesCount() {
        // var min = 0.0;
        var max = 0.0;
        var coun = 0;
        this.cartItems.forEach(function (value) {
            if (value.t_rel_limg_id == null) {
                coun += 1;
            }
            value.cartproducts.forEach(function (values) {
                // if (values.is_mandatory == 'y')
                // {
                //   min += parseFloat(values.total_price);
                // }
                max += parseFloat(values.total_price);
            });
        });
        // this.totalMinAmount = min;
        this.totalMaxAmount = max;
        this.count = coun;
        this.totalMaxAmountProducts = this.totalMaxAmount;

    }

    viewImagePopup(image: string) {
        this.productImage = image;
    }


    handleError(error) {
        this.loading = false;
        Object.keys(error.error.errors).forEach(field => {
            this.serverFieldErrors[field] = error.error.errors[field][0];
        });
        // this.alertService.error(this.title, error.error.errors.message);
    }
    toggled(shown) {
        this.byDefault = shown;
        var tot_count = [];
      
            for (let j = 0; j < this.alldata.cart[shown].cartproducts.length; j++) {

                var tot_price = parseInt(this.alldata.cart[shown].cartproducts[j].total_price);
                tot_count.push(tot_price);
                console.log(tot_price);
                // alert(a);
            }
        
        this.adding_tot = tot_count.reduce(myFunc);
        console.log(this.adding_tot);
        function myFunc(total, num) {
            return total + num
        }
        console.log(tot_count);
    }

    // setModalImg(image: string) {
    //     this.wishlistImage = image;
    // }
    popup(image: string,unique_id,theme)
    {
      Swal.fire({
        imageUrl: image,
        title: 'Would you like to finalize this design or remove it.',
        width: 1000,
        imageWidth: 1000,
        showClass: {
          popup: 'swal-wide animated fadeInDown faster'
        },
     
        hideClass: {
          popup: 'swal-cart-wide animated fadeOutUp faster'
        },
        showConfirmButton: true,
       showCloseButton: true,
       showCancelButton: true,
       confirmButtonText: 'Yes, Add to Cart!',
       cancelButtonText: 'Remove From Wishlist',
       padding: '3em',
        
        //background: image,
      }).then((result) => {
        if (result.isConfirmed) {
            this.addToCart(unique_id);
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.deleteFromWishlist(unique_id,theme);
        }
    })
}


log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }

    demoNotAvailable()
    {
        Swal.fire({
            title: 'Your design changes have been considered, please wait for a few mins while we work on it, Thank you.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'OK',
            confirmButtonColor: 'primary',
        }).then((check) => {
        
        })
    }
  
}
