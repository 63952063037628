import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

import config from '../config/config';

@Injectable({
	providedIn: 'root'
})
export class TokenService {

	apiUrl: string = config.API_ENDPOINT;

	private iss = {
		login: `${this.apiUrl}/auth/login`,
		signup: `${this.apiUrl}/auth/signup`
	};

	constructor() {
	}

	handle(token) {
		this.set(token);
		// console.log(this.isValid());
	}

	set(token) {
		localStorage.setItem('token', token);
	}

	get() {
		return localStorage.getItem('token');
	}

	getToken() {
		return localStorage.getItem('token');
	}

	remove() {
		//localStorage.removeItem('token');
		localStorage.removeItem('cust_id');
		localStorage.removeItem('cust_otp_verified');
	}

	isValid() {
		const token = this.get();
		// console.log(token);
		if (token) {
			const payload = this.payload(token);
			// console.log(payload);
			if (payload) {
				// console.log(this.iss.login);
				// return (payload.iss === this.apiUrl + '/auth/login') ? true : false;
				// return (payload.iss === this.iss.login) ? true : false;

				// return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
				return (Object.values(this.iss).indexOf(payload.iss) > -1 
					// && (payload.sub == localStorage.getItem('userId')) 
					&& !this.isTokenExpired()) ? true : false;
			}
		}
		return false;
	}

	payload(token) {
		const payload = token.split('.')[1];
		if(payload)
			return this.decode(payload);
	}

	decode(payload) {
		return JSON.parse(atob(payload));
	}

	loggedIn() {
		return this.isValid();
	}

	otpVerified() {
		if(localStorage.getItem('cust_otp_verified'))
			return true;

		return false;
	}

	getTokenExpirationDate(token: string): Date {
		const decoded = this.payload(token);

		if (decoded.exp === undefined) return null;

		const date = new Date(0); 
		date.setUTCSeconds(decoded.exp);
		return date;
	}

	isTokenExpired(token?: string): boolean {
		if(!token) token = this.getToken();
		if(!token) return true;

		const date = this.getTokenExpirationDate(token);
		if(date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}

	getCurrentUser() {
		let token = this.getToken();
		const decoded = this.payload(token);

		return decoded;
	}
}

