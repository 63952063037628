import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, AbstractControl, FormGroup,Validators} from '@angular/forms'
import { AccountService } from '../../services/account.service';
import { DataService } from '../data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.css']
})
export class LoginpageComponent implements OnInit {
    public isSubmit: boolean = false;
    public loading: boolean = false;
    public mobileError: boolean = false;
  
    public form = {
        mobile: null
    };
    cust_mobile: number;
    package: any;
    constructor(
        private router: Router, 
        private accountService: AccountService,
        private dataService: DataService) {}

    ngOnInit() {}

    sendOtp() {
        this.isSubmit = true;
		    this.loading = true;

        this.cust_mobile = this.form.mobile;
        //getPackageCount
        this.dataService.checkPackage(this.cust_mobile).subscribe(res => {
            this.package = res;
            if(this.package > 0)
            {
                this.accountService.sendCustomerOtp(this.form).subscribe(
           
                    data =>
                    {
                        this.loading = false;
                        this.isSubmit = false;
                        this.handleResponse(data)
                      
                    } ,
                    error => this.handleError(error)
                   
                );
            }
            else
            {
                Swal.fire({
                    title: '',
                    text: "Please go through the DEMO and make the payment to continue.",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'DEMO'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        this.router.navigateByUrl('/welcomepage');
                    }
                  })
            }
        });
        
    }

    //START ->  On key press for only numbers
    omit_char(event)
    {   
        var k;  
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        if ((k < 48 || k > 57) && k !== 13) {
            event.preventDefault();
            return false;
        } 
    }
    //END ->  On key press for only numbers

    handleResponse(data) {
        localStorage.setItem('cust_id', data.cust_id);
        this.router.navigateByUrl('/otppage');
    }

    handleError(error) {
        this.mobileError = true;
        this.loading = false;
        console.log(error);
    }

}
