import { Component, OnInit, ViewEncapsulation,Input,ViewChild, ElementRef ,TemplateRef } from '@angular/core';
import { DataService } from '../data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators, NgForm  } from '@angular/forms';
import 'sweetalert2/src/sweetalert2.scss';
import { ExcelServiceService } from '../../services/excel-service.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import config from '../../config/config';
import { HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { pid } from 'process';
// import   font from '../../../assets/fonts/gothic-bold.js';
// import * as jspdf_autotable from 'jspdf-autotable';
declare var $: any; 


export interface serverFieldErrors {
    email: string;
}

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.css'],
    encapsulation: ViewEncapsulation.None

})

export class CartComponent implements OnInit {
    public serverFieldErrors: serverFieldErrors = {
        email: ''
    };
    @ViewChild('closeModal', {static : false}) closeModal: ElementRef
    @ViewChild('pdfTable', {
        static: false
    }) pdfTable: ElementRef;
    @ViewChild("content", {
        static: false
    }) modalContent: TemplateRef < any > ;
    @Input('compare') compare: Compare;
    @Input('wishlist') wishlist: Wishlist;
    //   @Input() cart: Cart;
    apiUrl: string = config.API_ENDPOINT;
    logout:any;
    cartItems: any;
    registermailForm: FormGroup;
    id: any;
    alldata: any;
    service: any;
    delete: any;
    cust_id: number;
    totalMaxAmount: number;
    totalAmountCart:number;
    count: number;
    excel: any = [];
    public isSubmit: boolean = false;
    public loading: boolean = false;
    public loadingservice: boolean = false;
    public modalSubmit: boolean = false;
    public form: FormGroup;
    public contactList: FormArray;
    offshellcartItems: any;
    totalMaxAmountProducts: number;
    otherparams: any = {};
    params: any = {};
    cartItemsLength: number;
    offshell: any;
    offshellLength: number;
    quantity = 1;
    tax:any;
    getData: any;
    excel1: any;
    excel2: any;
    offshelf: any;
    entire: any;
    single: any;
    productImage: string;
    catName: string;
    catUid: string;
    customerdetails: any;
    public isDisabled: boolean = true;
    emailcustomer: any;
    download: string;
    entirehouse: string;
    singleRoom: string;
    mobilecustomer: any;
    cart: any;
    cartcount: any;
    public services : any;
    public addons : any;
    public terms : any;    
    constructor(
        private httpClient: HttpClient, private dataService: DataService,
        public modalService: NgbModal,
        private formBuilder: FormBuilder,
        private router: Router,
        private _location: Location,
        private excelService: ExcelServiceService
    ) {}

    ngOnInit() {

        document.onreadystatechange = function() {
            let desc1 = document.getElementsByClassName('fadeOut') as HTMLCollectionOf<HTMLElement>;
            let desc2 = document.getElementsByClassName('cart-section') as HTMLCollectionOf<HTMLElement>;
            for (let i = 0; i < desc1.length; i++) {
              let element = desc1[i];
              let elem = desc2[i];
              if (document.readyState !== "complete") { 
                  elem.style.visibility = "hidden"; 
                  element.style.visibility = "visible"; 
              } else { 
                  element.style.display = "none"; 
                  elem.style.visibility = "visible"; 
              } 
            }
        };
        
        this.updatecart();
        this.form = this.formBuilder.group({
            unique_id: '',
            name: ['']
            // attrs: this.formBuilder.array([this.createAttrFormGroup()],[Validators.required])
        });
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.httpClient.get(`${this.apiUrl}/front/getExportList/` + this.cust_id).subscribe(data => {
            this.excel1 = data;
            this.offshelf = this.excel1.offshelf;
            this.entire = this.excel1.entire;
            this.single = this.excel1.single;
        });

        this.registermailForm = this.formBuilder.group({
            email: ['', Validators.required]
        });

        this.dataService.getProfileDetail(this.cust_id).subscribe(
            data => {
                this.customerdetails = data;
                this.customerdetails.email = '';
                this.emailcustomer = this.customerdetails.email
                this.mobilecustomer = this.customerdetails.mobile_no
                if (this.emailcustomer !== null) {
                    this.isDisabled = true;
                }
                this.registermailForm.patchValue({
                    email: (this.customerdetails.email)
                });
            }
        );

        this.contactList = this.form.get('contacts') as FormArray;
        this.initial();
        this.getServices();
        this.getAddons();
        this.getTeams();
    }

    getServices() {
        this.dataService.getServiceDetails().subscribe((resp:any) => {
                this.services = resp.data.reverse();
        });
    }
    getAddons() {
        this.checkedAddon = [];
        this.dataService.getAddonDetails().subscribe((resp:any) => {
            this.addons = resp.data.reverse();
            this.checkedAddon = this.checkedAddon.concat(this.addons[0])
            this.checkedAddon = this.checkedAddon.concat(this.addons[1])
            this.checkedAddon = this.checkedAddon.concat(this.addons[2])
        });
    }
    getTeams() {
        this.dataService.getTermDetails().subscribe((resp:any) => {
                this.terms = resp.termsdesc;
        });
    }

    public checkedAddon = [];
    public checkedService = [];
    addonChange(e, data) {
        if(e.target.checked) {
            this.checkedAddon = this.checkedAddon.concat(data)
            this.totalMaxAmountProducts = this.totalMaxAmountProducts + Number(data.uom_amount)
        }else{
            this.checkedAddon = this.checkedAddon.filter(item => item.id != data.id)
            this.totalMaxAmountProducts = this.totalMaxAmountProducts - Number(data.uom_amount)
        }
    }

    serviceChange(e, data) {
        if(e.target.checked) {
            this.checkedService = this.checkedService.concat(data)
            this.totalMaxAmountProducts = this.totalMaxAmountProducts + Number(data.uom_amount)
        }else{
            this.checkedService = this.checkedService.filter(item => item.id != data.id)
            this.totalMaxAmountProducts = this.totalMaxAmountProducts - Number(data.uom_amount)
        }
    }


    quantityUpdate(evt, id) {
        let objIndex = this.addons.findIndex((obj => obj.id == id));
        this.addons[objIndex].uom_quantity = evt.target.value;
        this.addons[objIndex].uom_amount = evt.target.value * this.addons[objIndex].uom_rate;
    }

    quantityServiceUpdate(evt, id) {
        let objIndex = this.services.findIndex((obj => obj.id == id));
        this.services[objIndex].uom_quantity = evt.target.value;
        this.services[objIndex].uom_amount = evt.target.value * this.services[objIndex].uom_rate;
    }

    exportAsXLSX(): void {
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.httpClient.get(`${this.apiUrl}/front/getExportList/` + this.cust_id).subscribe(data => {
            this.excel = data;
            this.excelService.exportAsExcelFile(this.excel, this.mobilecustomer);
        });
    }

    increment(id, qty, price,tax) {
        qty++;
        this.quantity = qty;
        this.params.quantity = this.quantity;
        this.params.price = price;
        this.params.tax = tax;
        this.params.unique_id = id;
        this.dataService.changeQuantity(this.params).subscribe(
            data => {
                this.updatecart();
                this.delete = data;

                this.initial();
            }
        );
        this.calculatePriceAndMessagesCount();
    }

    decrement(id, qty, price,tax) {
        if (qty != 1) {
            qty--;
        }
        this.quantity = qty;
        this.params.quantity = this.quantity;
        this.params.price = price;
        this.params.unique_id = id;
        this.params.tax = tax;
        this.dataService.changeQuantity(this.params).subscribe(
            data => {
                this.updatecart();
                this.delete = data;

                this.initial();
            }
        );
        this.calculatePriceAndMessagesCount();
    }

    goback() {
        this._location.back();
    }

    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        console.log("image");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL;
    }

    convert() {
        function getFormattedTime() {
            var today = new Date();
            var y = today.getFullYear();
            var m = today.getMonth() + 1;
            var d = today.getDate();
            var h = today.getHours();
            return y + "_" + m + "_" + d + "_" + h;
        }
        var doc: any = new jsPDF('l', 'mm', 'a4');
        var doc1: any = new jsPDF('l', 'mm', 'a4');
        var doc2: any = new jsPDF('l', 'mm', 'a4');
        var col = ["Image", "Product Details", "Quantity", "Price", "Tax", "Total Price"];
        var col1 = ["Image", "Product Details", "Quantity","Price", "Tax", "Total Price"];
        var col2 = ["Image", "Product Details", "Quantity","Price", "Tax", "Total Price"];
        var rows = [];
        this.entirehouse = this.mobilecustomer + '__' + getFormattedTime() + '__Entire_House' + '.pdf'
        this.singleRoom = this.mobilecustomer + '__' + getFormattedTime() + '__Single_Room' + '.pdf'
        this.offshelf = this.mobilecustomer + '__' + getFormattedTime() + '__Off_The_Shelf' + '.pdf'
        var rows1 = [];
        var rows2 = [];
        var imgElements1 = [];
        var imgElements2 = [];
        var imgElements3 = [];
        var images1 = [];
        var images2 = [];
        var category=[];
        var images3 = [];
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.httpClient.get(`${this.apiUrl}/front/getPdfDownload/` + this.cust_id).subscribe(data => {
            this.excel2 = data;
            var offshelfproducts = this.excel2.offshelf;
            var entireproducts = this.excel2.entire;
            var singleproducts = this.excel2.single;
            // var addonProducts = this.addons
            if(offshelfproducts.length)
            {
              
            offshelfproducts.forEach(element =>
                 {
                var temp1 = [
                    '',
                    'PID  '+ '\xa0\xa0\xa0\xa0\xa0\xa0\xa0 : '+ element.Product_id + '\n'+
                    'Element '+ '\xa0 : '+ element.Element + '\n'+
                    'Product  '+ '\xa0 : '+ element.Product ,
                    element.Quantity, 
                    'Rs.'+element.Price, 
                    element.Tax, 
                    'Rs.'+element.Total_price
                ];
                if (temp1) {
                    imgElements1.push(element.Image_raw);
                    rows.push(temp1);
                    
                }
                });
                // Calculate TotalAmount
                var max = 0.0;
                offshelfproducts.forEach(function(values) {
                        max += parseFloat(values.Total_price);
                });

                this.totalAmountCart = max;
                var temp22 = ["","","","","TOTAL", "Rs."+this.totalAmountCart.toFixed(2)];
                rows.push(temp22);

            }
            if (rows.length != 0) {
                doc.autoTable(col, rows, {
                    styles: {
                        valign: 'middle',
                        halign: 'center',
                        overflowColumns: 'linebreak',
                        overflow: 'linebreak'
                    },
                    headerStyles: {
                        // fillColor: [9, 54, 74],
                        // fontSize: 11
                    },
                    // alternateRowStyles: {
                    //     fillColor: [255, 255, 255]
                    // },
                    // theme:'grid',
                    
                    pageBreak: 'auto', 
                    columnStyles: {
                        0: { columnWidth: 30,  halign: 'left' },
                        1:{ 
                           columnWidth: 130 ,
                           halign: 'left'
                          }
                    },
                    drawRow: function (row, data) {
                        row.height = 36.5
                        if (row.cells[4].text[0] == ["TOTAL"]) {
							
                            row.height = 10;
                            row.cells[4].styles.fontSize = 11;
                            row.cells[4].styles.fontStyle = "bold";
                            row.cells[4].styles.valign = "middle";
                            row.cells[5].styles.fontStyle = "bold";
                        }
                    }, 
                    drawCell: function(cell, opts) {
                        if(opts.column.dataKey === 0 && opts.row.index < rows.length-1) {
                            images1.push({
                            elem: imgElements1[opts.row.index],
                                x: cell.textPos.x,
                                y: cell.textPos.y
                            });
                        }
                    },
                    addPageContent: function() {
                        for (var i = 0; i < images1.length; i++) {
                            if (!images1[i].isLoad) {
                                doc.addImage(images1[i].elem, images1[i].x, images1[i].y - 10, 20, 20);
                                images1[i].isLoad = true;
                            }
                        }
                    }
                });
                 doc.save(this.offshelf);
            }
            entireproducts.forEach(element => 
                {
                var temp = [
                    '', 
                    'PID  '+ '\xa0\xa0\xa0\xa0\xa0\xa0\xa0 : '+ element.Product_id + '\n'+
                    'Element '+ '\xa0 : '+ element.Element + '\n'+
                    'Product  '+ '\xa0 : '+ element.Product ,
                     element.Quantity, 
                    'Rs.'+element.Price, 
                     element.Tax, 
                    'Rs.'+element.Total_price
                ];
                if (temp) {
                    imgElements2.push(element.Image_raw);
                    rows1.push(temp);
                }
                // Calculate TotalAmount
                if (temp) {
                var max = 0.0;
                entireproducts.forEach(function(values) {
                        max += parseFloat(values.Total_price);
                });
                this.totalAmountCart = max;
                var entireTotal = ["","","","","Total", "Rs."+this.totalAmountCart.toFixed(2)];
                rows1.push(entireTotal);

            }
            });
            if (rows1.length != 0) {
                doc1.autoTable(col1, rows1, {
                    styles: {
                        valign: 'middle'
                    },
                    columnStyles: {
                        0: { columnWidth: 30 }
                    },
                    drawRow: function (row, data) {
                        row.height = 35
                    }, 
                    drawCell: function(cell, opts) {
                        if(opts.column.dataKey === 0 && opts.row.index < rows1.length) {
                            images2.push({
                            elem: imgElements2[opts.row.index],
                                x: cell.textPos.x,
                                y: cell.textPos.y
                            });
                        }
                    },
                    addPageContent: function() {
                        for (var i = 0; i < images2.length; i++) {
                            if (!images2[i].isLoad) {
                                doc1.addImage(images2[i].elem, images2[i].x, images2[i].y - 10, 20, 20);
                                images2[i].isLoad = true;
                            }
                        }
                    }
                });
                doc1.save(this.entirehouse);
            }
            var ind=0;
        //    if(singleproducts!=null)
        //    {
            singleproducts.forEach(element => {
                       var datalist=[];
                       datalist.push(element.Category+'\n' +element.Product);
                       var temp2 = [
                        '', 
                        'PID     '+ '\xa0\xa0\xa0\xa0 : ' + element.Product_id + '\n'+
                        'Category'+  '\xa0: '+ element.Category +  '\n'+
                        'Element '+ '\xa0 : '+ element.Element+ '\n'+
                        'Product  '+ '\xa0 : '+ element.Product ,
                         element.Quantity, 
                        'Rs.'+element.Price, 
                         element.Tax, 
                        'Rs.'+element.Total_price
                       ];
                        if (temp2)
                        {
                            imgElements3.push(element.Image_raw);
                            rows2.push(temp2);
                        }
                 
            });

            // addonProducts.forEach(element => {
            //            // var datalist1=[];
            //            // datalist1.push(element.Category+'\n' +element.Product);
            //            var temp2 = [
            //             '', 
            //             // 'PID     '+ '\xa0\xa0\xa0\xa0 : ' + element.Product_id + '\n'+
            //             // 'Category'+  '\xa0: '+ element.Category +  '\n'+
            //             // 'Element '+ '\xa0 : '+ element.Element+ '\n'+
            //             // 'Product  '+ '\xa0 : '+ element.Product ,
            //              element.name, 
            //              element.desc, 
            //              element.uom_quantity, 
            //              element.uom_amount
            //            ];
            //             // if (temp2)
            //             // {
            //             //     imgElements3.push(element.Image_raw);
            //                 rows2.push(temp2);
            //             }
                 
            // });
               //Calculate TotalAmount
                var max = 0.0;
                singleproducts.forEach(function(values) {
                        max += parseFloat(values.Total_price);
                });
                this.totalAmountCart = max;
                var singleTotalAmount = ["","","","","TOTAL", "Rs."+this.totalAmountCart.toFixed(2)];
                rows2.push(singleTotalAmount);
          // }

            if (rows2.length != 0)
            {   
                doc2.autoTable(col2, rows2, {
                    styles: {
                        valign: 'middle',
                        halign: 'center',
                        overflowColumns: 'linebreak',
                        overflow: 'linebreak'
                    },
                    headerStyles: {
                        // fillColor: [9, 54, 74],
                        // fontSize: 11
                    },
                    // alternateRowStyles: {
                    //     fillColor: [255, 255, 255]
                    // },
                    // theme:'grid',
                    
                    pageBreak: 'auto', 
                    columnStyles: {
                        0: { columnWidth: 30,  halign: 'left' },
                        1:{ 
                           columnWidth: 130 ,
                           halign: 'left'
                          }
                    },
                    didParseCell: function (data) {
                        data.row.height = 10;
                        var rows = data.table.body;
                        if (data.row.index === rows.length - 1) {
                            data.cell.styles.fontSize = 11;
                            data.cell.styles.fontStyle = "bold";
                            data.cell.styles.valign = "middle";
                            data.cell.styles.cellPadding = 0;
                        }
                    // drawRow: function (row, data) {
                    //     row.height = 36.5;
                    //     row.fillColor= [255, 255, 255]
                      
                    //     if (row.cells[4].text[0] == ["TOTAL"]) {
                    //         row.height = 10;
                    //         row.cells[2].fillColor = "#fff";
                    //         row.cells[4].styles.fontSize = 11;
                    //         row.cells[4].styles.fontStyle = "bold";
                    //         row.cells[4].styles.valign = "middle";
                    //         row.cells[5].styles.fontStyle = "bold";
                    //     }
                    }, 
                    drawCell: function(cell, data) {
                        if(data.column.dataKey === 0 && data.row.index < rows2.length-1) {
                         
                            images3.push({
                                elem: imgElements3[data.row.index],
                                    x: cell.textPos.x,
                                    y: cell.textPos.y
                                });
                        }
                    },
                    addPageContent: function() {
                        for (var i = 0; i < images3.length; i++) {
                            if (!images3[i].isLoad) {
                                doc2.addImage(images3[i].elem, images3[i].x, images3[i].y - 10, 20, 20);
                                images3[i].isLoad = true;
                            } 
                        }
                    }
                });
                doc2.save(this.singleRoom);
            }
        
        });
    }

    initial() {
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.dataService.getCartProducts(this.cust_id).subscribe(
            data => {
                this.alldata = data;
                this.cartItems = this.alldata.cart;
                this.offshellcartItems = this.alldata.offshelf;
                this.cartItemsLength = this.alldata.cart.length;
                this.offshellLength = this.alldata.offshelf.length;
                if (this.cartItemsLength > 0 || this.offshellLength > 0) {
                    this.calculatePriceAndMessagesCount();
                }
            }
        );
    }

    counter(n: number): any[] {
        return Array(n);
    }

    proceed() {
        console.log()
        if(this.registermailForm.controls['email'].value == '') {
            $("#myModal").modal("hide");
        }else{
            $("#myModal").modal("show");
        }
    }

    openVerticallyCentered(servicelife) {
        // console.log(servicelife);
        this.modalService.open(servicelife, {
            centered: true,
            scrollable: true,
            size: 'sm',
            backdropClass: 'light-blue-backdrop',
            windowClass: 'service-modal ng-x'
        });
    }

    openVerticallyCentered2(servicelife2) {
        // console.log(servicelife2);
        this.modalService.open(servicelife2, {
            centered: true,
            scrollable: true,
            size: 'sm',
            backdropClass: 'light-blue-backdrop',
            windowClass: 'service-modal ng-x'
        });
    }

    get name() {
        return this.form.controls['name'];
    }
    get unique_id() {
        return this.form.controls['unique_id'];
    }
    get email() {
        return this.registermailForm.controls['email'];
    }
    requestServiceLive(catUid, catName) {
        this.modalSubmit = false;
        this.loadingservice = false;
        this.catName = catName;
        this.form.patchValue({
            unique_id: catUid
        });
        this.form.controls['name'].setValue('');
    }
 
    submitServiceLiveRequest() {

        this.modalSubmit = true;
        this.loadingservice = true;

        // if (this.form.invalid) {
        //     this.loadingservice = false;
        //     return;
        // }
        this.otherparams.custId = this.cust_id;
        this.otherparams.total = this.totalMaxAmountProducts;
        this.dataService.requestServiceLive(this.form.value, this.otherparams).subscribe(
            data => {
               
                this.service = data;
                this.loadingservice = false;
                this.modalSubmit = false;
                this.form.reset();
                if (this.service.message == 1) {
                    this.modalService.dismissAll();
                    this.loadingservice = false;
                    this.modalSubmit = false;
                    this.form.reset();
                    this.modalService.open(this.modalContent).result.then((result) => {

                    });
                    this.initial();
                   
                } else {
                    Swal.fire('Please try again later.');
                }
                this.loadingservice = false;
                this.modalSubmit = false;
                this.form.reset();
            }
        );
    }

    createProject() {

        var check = 0;
        this.cartItems.forEach(function(value) {
            if (value.type == 0) {
                if (value.status == null) {
                    console.log(value);
                    check++;
                }

            }
        });
        if (check > 0) {
            Swal.fire('Please request layouts for service live.');
            return;

        } else {

            this.isSubmit = true;
            this.loading = true;

            if (this.registermailForm.invalid) {
                this.loading = false;
                return;
            }
            this.otherparams.custId = this.cust_id;
            this.otherparams.total = this.totalMaxAmountProducts;
            this.otherparams.addons = this.checkedAddon
            this.otherparams.services = this.checkedService
            this.dataService.createProjectServiceLive(this.registermailForm.value, this.otherparams).subscribe(
                data => {
                    
                    this.isSubmit = false;
                    this.loading = false;
                    this.service = data;
                    if (this.service.message == 1) {
                        this.modalService.dismissAll();
                     
                        Swal.fire({
                            imageUrl: 'assets/images/logo1.png.png',
                            title: 'Thank you for your inputs. <br>You will receive a mail from our end with your selection!<br> By clicking on this link ' + this.service.payment_link,
                            width: 600,
                            imageWidth: 120,
                            showClass: {
                              popup: 'swal-cart-wide animated fadeInDown faster'
                            },
                       
                            hideClass: {
                              popup: 'swal-cart-wide animated fadeOutUp faster'
                            },
                            showConfirmButton: false,
                           showCloseButton: true,
                           showCancelButton: false,
                            padding: '3em',
                            // timer:6500,
                            background: 'url(assets/images/background/welcombg.jpg)no-repeat center',
                           
                            backdrop: `
                            #09364aa6
                             
                              left top
                              no-repeat
                            `
                          }).then(function(){
// <<<<<<< HEAD
                            // var token = localStorage.getItem('token');
                            //  localStorage.clear();
                            // localStorage.setItem('token', token);
                            // window.location.href = "/landing";
                            // window.location.href = "/elementlist";
                            // window.location.href = "/persft/elementlist";
// =======
//                             var token = localStorage.getItem('token');
//                             var custid =  localStorage.getItem('cust_id');
//                             var cust_otp_verified = localStorage.getItem('cust_otp_verified');
//                             localStorage.clear();
//                             localStorage.setItem('token', token);
//                             localStorage.setItem('cust_otp_verified', cust_otp_verified);
//                             localStorage.setItem('cust_id', custid);
// >>>>>>> e93dc8d1b2a57ab72b39445f3f75396b81f5ab4a
                                // this.redirectToElementList();
                          })
                       
                       
                    } else {
                        Swal.fire('Please try again later.');
                    }

                },
                error => this.handleError(error),
            );
            this.loading = true;
            this.isSubmit = true;
          
         
        }
     

    }

    // redirectToElementList(){
    //     this.router.navigateByUrl('/elementlist');
    // }
    updatecart() {
        this.cart = new Cart();
        this.cust_id = parseInt(localStorage.getItem("cust_id"));
        this.dataService.getcountProducts(this.cust_id).subscribe(res => {
            this.cart.cartcount = res;
            this.cartcount = this.cart.cartcount;
        });
    }

    deleteFromCart(unique_id,theme) {
        var indexValue = this.cartItems.findIndex(selectcategory => selectcategory.unique_id === unique_id);
        this.cartItems.splice(indexValue, 1); //splice at newindex
        this.calculatePriceAndMessagesCount();
        this.params.type = "3"; //1->offshelf product 2->single product; 3->entire catalogue
        this.params.unique_id = unique_id;
        this.dataService.deleteFromCart(this.params).subscribe(
            data => {
                this.delete = data;
                if(theme!=""){
                    if(localStorage.getItem("entireHouseStatus")!== null && localStorage.getItem("entireHouseAmounts")!== null && localStorage.getItem("entireHouseCart")!== null){
                        var status=JSON.parse(localStorage.getItem("entireHouseStatus"));
                        var amounts=JSON.parse(localStorage.getItem("entireHouseAmounts"));
                        var carts=JSON.parse(localStorage.getItem("entireHouseCart"));
                        var indexValue = carts.findIndex(cart => cart == unique_id);
                        if(indexValue!=-1){
                            status.splice(indexValue,1,'N'); //splice at newindex
                            amounts.splice(indexValue,1,'0'); //splice at newindex
                            carts.splice(indexValue,1,'0'); //splice at newindex
                            localStorage.setItem("entireHouseStatus",JSON.stringify(status));
                            localStorage.setItem("entireHouseAmounts",JSON.stringify(amounts));
                            localStorage.setItem("entireHouseCart",JSON.stringify(carts));
                        }
                    }
                }
                this.updatecart();
                this.initial();

            }
        );

    }

    deleteProductCartCategory(unique_id, pid, row, col) {
        var deleteIndex;
        var indexValue = this.cartItems.findIndex(selectcategory => selectcategory.unique_id === unique_id);
        if (this.cartItems[indexValue].cartproducts.length > 1) {
            deleteIndex = this.cartItems[indexValue].cartproducts.findIndex(selectproduct => selectproduct.pid === pid);
            this.cartItems[indexValue].cartproducts.splice(deleteIndex, 1);
            this.calculatePriceAndMessagesCount();
            this.params.type = "2"; //1->offshelf product 2->single product; 3->entire catalogue
            this.params.unique_id = unique_id;
            this.params.pid = pid;
            this.params.row = row;
            this.params.col = col;
            this.dataService.deleteFromCart(this.params).subscribe(
                data => {
                    this.delete = data;
                    this.updatecart();
                    this.initial();

                }
            );
        } else {
            Swal.fire('One product is mandatory');
        }
    }

    deleteProductCart(unique_id, pid) {
        var deleteIndex = this.offshellcartItems.findIndex(selectproduct => selectproduct.pid === pid);
        this.offshellcartItems.splice(deleteIndex, 1);
        this.calculatePriceAndMessagesCount();
        this.params.type = "1"; //1->offshelf product 2->single product; 3->entire catalogue
        this.params.unique_id = unique_id;
        this.params.pid = pid;
        this.dataService.deleteFromCart(this.params).subscribe(
            data => {
                this.delete = data;
                this.updatecart();
                this.initial();
            }
        );
    }

    calculatePriceAndMessagesCount() {
        // var min = 0.0;
        var max = 0.0;
        var coun = 0;
        this.cartItems.forEach(function(value) {
            if (value.t_rel_limg_id == null) {
                coun += 1;
            }
            value.cartproducts.forEach(function(values) {
                // if (values.is_mandatory == 'y')
                // {
                //   min += parseFloat(values.total_price);
                // }
                max += parseFloat(values.total_price);
            });
        });
        // this.totalMinAmount = min;
        this.totalMaxAmount = max;
        this.count = coun;

        var offshellprice = 0.0;
        this.offshellcartItems.forEach(function(value) {
   
            // offshellprice += (parseFloat(value.total_price) * parseInt(value.quantity))+((parseFloat(value.total_price)*parseFloat(value.tax))/100)
            offshellprice += (parseFloat(value.total_price) );
        });
        this.totalMaxAmountProducts = this.totalMaxAmount + offshellprice;

    }

    viewImagePopup(image: string) {
        this.productImage = image;
    }

    viewImagePopup2(image: string) {
        this.productImage = image;
    }


   
    handleError(error) {
        this.loading = false;
        Object.keys(error.error.errors).forEach(field => {
            this.serverFieldErrors[field] = error.error.errors[field][0];
        });
        // this.alertService.error(this.title, error.error.errors.message);
    }

    finalize()
    {
        Swal.fire({
            title: 'Do you want to finalize?',
            text: "If you click yes, you won't be able to make changes.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
          }).then((result) => {
            if (result.value) {
                this.createProject();
            }
          })
    }

    sendMail()
    {
            if (this.registermailForm.invalid) {
                this.loading = false;
                return;
            }
        this.otherparams.custId = this.cust_id;
        this.dataService.sendProjectMail(this.registermailForm.value, this.otherparams).subscribe(
            data => {
                Swal.fire({
                    title: 'Mail Sent',
                    icon: 'success'
                })
            }
        )
    }

    log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }
}