import { Component,ViewChild , OnInit,Input, ViewEncapsulation  } from '@angular/core';
import { Location } from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ServicesComponent implements OnInit {
  @Input('compare') compare: Compare;
  @Input('wishlist') wishlist: Wishlist;
  @Input() cart:Cart;
  cust_id: number;
  logout:any;
  public getcount: any;
  goback() {
		this._location.back();
    }
    constructor(private _location: Location,private router: Router,
      private route: ActivatedRoute) { }
  ngOnInit() {
    // $('.custom_tooltip_tag').trigger('mouseover');
    // document.onreadystatechange = function() {
    //   let desc1 = document.getElementsByClassName('custom_tooltip_tag') as HTMLCollectionOf<HTMLElement>;
    //   for (let i = 0; i < desc1.length; i++) {
    //     let element = desc1[i];
    //     element.style.visibility = "visible"; 
    //   }
    // };
    localStorage.removeItem('serviceType');
    localStorage.removeItem('selectedBhk');
    localStorage.removeItem('selectedTheme');
    localStorage.removeItem('filterEleId');
    localStorage.removeItem('filterVarId');
    localStorage.removeItem('filterVarVal');
    localStorage.removeItem('catalogueListing');
    localStorage.removeItem('subcatalogueListing');
    localStorage.removeItem('compareproductid');
    localStorage.removeItem('compareelementid');
    localStorage.removeItem('selectedSingleCat');
    localStorage.removeItem('selectedSubCat');
    localStorage.removeItem('entireHouseStatus');
    localStorage.removeItem('selectedEntireCat');
    localStorage.removeItem('selectedEntireCatIndex');
    localStorage.removeItem('entireHouseAmounts');
    localStorage.removeItem('entireHouseImages');
    localStorage.removeItem('entireHouseCart');
    localStorage.removeItem('selectedLength');
    localStorage.removeItem('selectedBreadth');
    localStorage.removeItem('selectedHeight');
    localStorage.removeItem('EntireHouseTheme');

  }

  ngAfterContentInit(){
    // alert(
      // $('.custom_tooltip_tag').trigger('mouseover');
      // );
  }

  log_out()
  {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: "Changes you made may not be saved",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/logout');
      }
    })
  }

}
