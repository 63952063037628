import { Component, OnInit, Input } from '@angular/core';
import { PackageCount } from './packagecount.model';
import { DataService } from '../data.service';

@Component({
  selector: 'app-packagecount',
  templateUrl: './packagecount.component.html',
  styleUrls: ['./packagecount.component.css']
})
export class PackageCountComponent implements OnInit {
  cust_id:any;
@Input('package') package:PackageCount;

constructor(private dataService: DataService)
{
}
  ngOnInit() {
    this.package=new PackageCount(); 
    this.cust_id = parseInt(localStorage.getItem("cust_id"));
    this.dataService.getPackagecount(this.cust_id).subscribe(res =>
    {
      this.dataService.getUsedcount(this.cust_id).subscribe(res1 =>
      {
        this.package.packagecount = ''+res1 +'/'+res+'';
        console.log('package_count',this.package.packagecount)
      });
    });
  }
}
