import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from '../data.service';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-elementlist',
  templateUrl: './elementlist.component.html',
  styleUrls: ['./elementlist.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ElementlistComponent implements OnInit
{
  @Input() cart:Cart;
  elementlists: any;
  // elementlistslength: number;
  logout:any;
  cust_id: number;
  @Input('compare') compare: Compare;

  constructor(private dataService: DataService, private _location: Location,
    private router: Router,
        private route: ActivatedRoute)
  {}

  customOptions: OwlOptions = {
    loop:true,
    autoplay:false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    items:1,
    margin:10,
    nav:true,
    smartSpeed:3000,
    dots: false,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    
      responsive:{
        0:{
          items:1,
          nav:true
        },
        640:{
          items:1,
          nav:true
        },			
        768:{
          items:1,
          nav:true
        },		
        991:{
          items:1,
          nav:true
        },
        
        1024:{
          items:1,
          nav:true
        },
        
        1280:{
          items:1,
          nav:true
        },		
        1600:{
          items:1,
          nav:true
        }				
        
      }
   
    }
    loadParams: any = {};

  goback()
  {
    this._location.back();
  }

  ngOnInit()
  {
    document.onreadystatechange = function() {
      let desc1 = document.getElementsByClassName('fadeOut') as HTMLCollectionOf<HTMLElement>;
      let desc2 = document.getElementsByClassName('our-projects') as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < desc1.length; i++) {
        let element = desc1[i];
        let elem = desc2[i];
        if (document.readyState !== "complete") { 
            elem.style.visibility = "hidden"; 
            element.style.visibility = "visible"; 
        } else { 
            element.style.display = "none"; 
            elem.style.visibility = "visible"; 
        } 
      }
  };
    localStorage.setItem("serviceType", '3');
    this.loadParams['serviceType'] = localStorage.getItem("serviceType");
    this.dataService.getElementLists(this.loadParams).subscribe(res =>
    {
      this.elementlists = res;
      // this.elementlistslength= this.elementlists.length -1;
    });
  }

  
log_out()
{
  Swal.fire({
    title: 'Are you sure you want to logout?',
    text: "Changes you made may not be saved",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.isConfirmed) {
      this.router.navigateByUrl('/logout');
    }
  })
}


}