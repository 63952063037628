import { Injectable } from '@angular/core';
// import {ToastData, ToastyConfig, ToastOptions, ToastyService} from 'ng2-toasty';

@Injectable({
	providedIn: 'root'
})

export class AlertService {

	position = 'top-right';
	title: string;
	msg: string;
	showClose = true;
	theme = 'default';
	type = 'default';
	timeout = 3000;
	closeOther = false;

	constructor(
		// private toastyService: ToastyService,
		// private toastyConfig: ToastyConfig,
		) { }

	// _showToast() {
	// 	if (this.closeOther) {
	// 		this.toastyService.clearAll();
	// 	}

	// 	const toastOptions: ToastOptions = {
	// 		title: this.title,
	// 		msg: this.msg,
	// 		showClose: this.showClose,
	// 		timeout: this.timeout,
	// 		theme: this.theme,
	// 		onAdd: (toast: ToastData) => {
	// 			/* added */
	// 		},
	// 		onRemove: (toast: ToastData) => {
	// 			/* removed */
	// 		}
	// 	};

	// 	switch (this.type) {
	// 		case 'default': this.toastyService.default(toastOptions); break;
	// 		case 'info': this.toastyService.info(toastOptions); break;
	// 		case 'success': this.toastyService.success(toastOptions); break;
	// 		case 'wait': this.toastyService.wait(toastOptions); break;
	// 		case 'error': this.toastyService.error(toastOptions); break;
	// 		case 'warning': this.toastyService.warning(toastOptions); break;
	//     }
	// }

    info(title, message) {
    	this.type = 'info';
    	this.title = title;
    	this.msg = message;
    	// console.log(this);
    	// this._showToast();
    }

    success(title, message) {
    	this.type = 'success';
    	this.title = title;
    	this.msg = message;
    	// this._showToast();
    }

    wait(title, message) {
    	this.type = 'wait';
    	this.title = title;
    	this.msg = message;
    	// this._showToast();
    }

    error(title, message) {
    	this.type = 'error';
    	this.title = title;
    	this.msg = message;
    	// this._showToast();
    }

    warning(title, message) {
    	this.type = 'warning';
    	this.title = title;
    	this.msg = message;
    	// this._showToast();
    }
	
}
