import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";

import config from '../config/config';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	apiUrl: string;

	private loggedIn = new BehaviorSubject<boolean>(this.tokenService.loggedIn());
	private otpVerified = new BehaviorSubject<boolean>(this.tokenService.otpVerified());
	
  	authStatus = this.loggedIn.asObservable();
  	otpVerifyStatus = this.otpVerified.asObservable();

	constructor(
		private http: HttpClient,
		private tokenService: TokenService
	) { 
		this.apiUrl = config.API_ENDPOINT
	}

	changeAuthStatus(value: boolean) {
		this.loggedIn.next(value);
	}

	changeOtpStatus(value: boolean) {
		this.loggedIn.next(value);
	}

	login(data) {
		return this.http.post(`${this.apiUrl}/auth/login`, data);
	}

	sendCustomerOtp(data) {
		return this.http.post(`${this.apiUrl}/auth/sendOtp`, data);
	}

	resendCustomerOtp(data) {
		return this.http.post(`${this.apiUrl}/auth/resendOtp`, data);
	}

	verifyCustomerOtp(data) {
		return this.http.post(`${this.apiUrl}/auth/verifyOtp`, data);
	}

	getMobileNumber(data) {
		return this.http.post(`${this.apiUrl}/auth/getMobileNumber`,data);
	}
}
