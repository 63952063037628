import { Component, OnInit, ViewEncapsulation,Input  } from '@angular/core'
import { Options,ChangeContext, PointerType } from 'ng5-slider';
import { DataService } from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import config from '../../config/config';
import {Location} from '@angular/common';
import { Cart } from '../cartcount/cartcount.model';
import { Compare} from '../comparecount/comparecount.model';
import { Wishlist} from '../wishlistcount/wishlistcount.model';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dimension',
  templateUrl: './dimension.component.html',
  styleUrls: ['./dimension.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class DimensionComponent implements OnInit {
    panelOpenState: boolean = false;
    logout:any;
    customCollapsedHeight: string = '42px';
    customExpandedHeight: string = '42px';
    @Input('compare') compare: Compare;
    @Input('wishlist') wishlist: Wishlist;
    @Input() cart:Cart;
    SelectedShape: any;
    serviceType:any;
    getFilterDetails: any;
    lengthSelected: any;
    BreadthSelected: any;
    heightSelected: any;
    BudgetSelected: any;
    checked: number;
    id: any;
    params:any= {};
    marked=false;
    theCheckbox=false;
    isNaN:any;
    noDataFound:string;
    message:string;
    imageshapes: Array<{value: string, label: string}> = config.IMAGE_SHAPE;
    sliderForm: FormGroup=new FormGroup( {
        sliderControl: new FormControl([20, 90980])
    }
    );
    options6: Options= {
        floor: 0, ceil: 10000000, step: 5
    }
    ;
    minBudgetValue: number=0;
    maxBudgetValue: number=2000000;
    options: Options= {
        floor: 0, ceil: 2000000
    }
    ;
    minLengthValue: number=0;
    maxLengthValue: number=2000000;
    options1: Options= {
        floor: 0, ceil: 25
    }
    ;
    minBreadthValue: number=33510;
    maxBreadthValue: number=2000000;
    options2: Options= {
        floor: 0, ceil: 25
    }
    ;
    minHeightValue: number=33310;
    maxHeightValue: number=2000000;
    options3: Options= {
        floor: 0, ceil: 15
    }
    ;
    loading: boolean;
    constructor(private dataService:DataService,
         private route:ActivatedRoute,
          private router: Router,
            private _location: Location) {}
    ngOnInit() {
        this.id=this.route.snapshot.params.id;
        this.serviceType = localStorage.getItem("serviceType");
        localStorage.removeItem('selectedLength');
        localStorage.removeItem('selectedBreadth');
        localStorage.removeItem('selectedHeight');
        if(this.serviceType == 2)
        {
            localStorage.setItem("selectedSingleCat",this.id);
        }
        this.SelectedShape=0;
        this.lengthSelected=0;
        this.heightSelected=0;
        this.BreadthSelected=0;
        this.BudgetSelected=0;
        // var subcatalogueID = JSON.parse(localStorage.getItem("singleRoom"));
        // var student3 = { subcatalogueID:this.id };
        // subcatalogueID.push(student3);
      // localStorage.setItem("singleRoom", JSON.stringify(subcatalogueID));
      // localStorage.removeItem(subcatalogueID);
        this.id=this.route.snapshot.params.id;
        this.params['id']=this.id;
        this.params['serviceType']=localStorage.getItem("serviceType");
        this.params['selectedTheme']=localStorage.getItem("selectedTheme");
        this.dataService.getMinMaxBudget(this.params).subscribe( data=> {
            if(data['min'] == 0.00 && data['max'] == 0.00)
            {
                this.options = {
                    floor: 0,
                    ceil: 2000000
                  };
            }
            else if(data['min'] == data['max']){
                this.options = {
                    floor: data['min']-1,
                    ceil: data['max'] 
                  };
            }
            
            else
            {
            this.options = {
              floor: data['min'],
              ceil: data['max']
            };
        }
        });
        const headers=new HttpHeaders();
        this.id=this.route.snapshot.params.id;
        this.params['id']=this.id;
        this.params['serviceType']=localStorage.getItem("serviceType");
        this.params['basetype']=1;

        // console.log(this.params);
        if((this.params['length']!=0 && this.params['height']!=0 &&  this.params['breadth']!=0) ||  this.params['shape']!=0 ||this.params['basetype']!=0 )
        {
            this.dataService.getLayoutImagesShape(this.params).subscribe( data=> {
                this.loading=true;
                this.getFilterDetails=data;
                this.loading = false;
                if (this.getFilterDetails == null || this.getFilterDetails.length === 0 || this.getFilterDetails === []) {
                    this.noDataFound="Search Results  Not Found";
                }
                else
                {
                    localStorage.setItem('catalogueListing', JSON.stringify(this.getFilterDetails));
                    if(this.serviceType == 1)
                    {
                        //this.router.navigateByUrl('/themeforentirehouse/'+this.id);
                    }
                    else
                    {
                        //this.router.navigateByUrl('/subcategory');
                    }
                }
            });
           
        }
    }
    goback() {
        this._location.back();
      }
    onkey() {
        //debugger
        /*if(this.theCheckbox==true) {
            this.marked=true;
        }
        else if(this.theCheckbox==false) {
            this.marked=false;
        }*/
        if (this.serviceType == 1) {
            this.router.navigateByUrl(`/themeforentirehouse/${this.id}?length=${this.lengthSelected}&breadth=${this.BreadthSelected}&height=${this.heightSelected}&min=${this.minBudgetValue}&max=${this.maxBudgetValue}&shape=${this.SelectedShape}`);
        }
        else {
            this.router.navigateByUrl('/subcategory');
        }
    }   
}